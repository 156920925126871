import React from "react";
import { css } from "@emotion/react";
import { Button, Typography } from "@material-ui/core";

const styles = {
  container: css``,
  heading: css`
    display: flex;
    align-items: center;
  `,
  headingText: css`
    flex: 1 1 auto;
  `,
  metrics: css`
    display: flex;
    padding-top: 20px;
    padding-bottom: 10px;
  `,
  metricBox: css`
    // flex: 1 1 auto;
    min-width: 220px;
  `,
  tastersBox: css`
  `,
  printButtonContainer: css`
    @media print
    {    
      display: none !important;
    }
  `
};

export interface ReportHeaderData {
  name: string;
  sessionName: string;
  liking: {
    score?: number;
    rank?: number;
  };
  conceptFit: {
    score?: number;
    rank?: number;
  };
  profile: {
    score?: number;
    rank?: number;
  };
  tasters: number;
}

export interface ReportHeaderProps extends ReportHeaderData {
  onPrint: () => void;
}

export function ReportHeader(p: ReportHeaderProps) {
  return (
    <div css={styles.container}>
      <div css={styles.heading}>
        <div css={styles.headingText}>
          <Typography variant="h2">
            <i>{p.name}</i> Report
          </Typography>
          {/* <Typography variant="h4">
            Part of project <i>{p.sessionName}</i>
          </Typography> */}
        </div>
        <div css={styles.printButtonContainer}>
          <Button variant="contained" onClick={e => p.onPrint()}>Print Report</Button>
        </div>
      </div>
      <div css={styles.metrics}>
        <div css={styles.metricBox}>
          <Typography variant="h5">Liking</Typography>
          <Typography>
            Average Score: <b>{p.liking.score == null ? 'N/A' : p.liking.score.toFixed(2)}</b>
          </Typography>
          <Typography>
            Rank: <b>{p.liking.rank}</b>
          </Typography>
          <Typography>Scale: 0-10</Typography>
        </div>
        <div css={styles.metricBox}>
          <Typography variant="h5">Concept Fit</Typography>
          <Typography>
            Average Score: <b>{p.conceptFit.score == null ? 'N/A' : p.conceptFit.score.toFixed(2)}</b>
          </Typography>
          <Typography>
            Rank: <b>{p.conceptFit.rank}</b>
          </Typography>
          <Typography>Scale: 1-5</Typography>
        </div>
        <div css={styles.metricBox}>
          <Typography variant="h5">Profile</Typography>
          <Typography>
            Average Score: <b>{p.profile.score == null ? 'N/A' : p.profile.score.toFixed(2)}%</b>
          </Typography>
          <Typography>
            Rank: <b>{p.profile.rank}</b>
          </Typography>
          <Typography>Scale: 0-100%</Typography>
        </div>
        <div css={css`flex-grow: 1;`}/>
        <div css={styles.tastersBox}>
          <Typography variant="h5">Tasters</Typography>
          <Typography variant="h4" align="center">
            {p.tasters}
          </Typography>
        </div>
      </div>
    </div>
  );
}
