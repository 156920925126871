import React from "react";
import { css } from "@emotion/react";
import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

export interface SampleDatum {
  id: string;
  name: string;
  likingMean?: number;
  likingMedian?: number;
  likingEntries: number;
  conceptFitMean?: number;
  conceptFitMedian?: number;
  conceptFitEntries: number;
  justAboutRightCount?: number;
  justAboutRightPossibleCount?: number;
  justAboutRightPercent?: number;
  tasters?: number;
}

interface SampleOverviewTableProps {
  samples: SampleDatum[];
}

function sub(minuend?: number, subtruend?: number): number | undefined {
  if (minuend != null && subtruend != null) {
    return minuend - subtruend;
  }
  return;
}

function percent(numerator?: number, denominator?: number) {
  if (numerator == null || denominator == null || denominator === 0) {
    return;
  }
  return (numerator * 100) / denominator;
}

function LabelledValue(p: {
  label?: string;
  value?: number;
  decimals?: number;
  suffix?: string;
}) {
  return (
    <div>
      {p.label == null ? "" : `${p.label}: `}
      {p.value == null
        ? "-"
        : `${p.value.toFixed(p.decimals == null ? 2 : p.decimals)}${
            p.suffix == null ? "" : p.suffix
          }`}
    </div>
  );
}

export default function SampleOverviewTable(p: SampleOverviewTableProps) {
  return (
    <Card>
      <CardContent>
        <Typography>Sample Summary</Typography>
      </CardContent>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Sample</TableCell>
              <TableCell>Liking</TableCell>
              <TableCell>Concept Fit</TableCell>
              <TableCell>Profile</TableCell>
              <TableCell>Tasters</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {p.samples.map((sample) => (
              <React.Fragment key={sample.id}>
                <TableRow>
                  <TableCell>{sample.name}</TableCell>
                  <TableCell css={css``}>
                    <LabelledValue label="Mean" value={sample.likingMean} />
                    <LabelledValue label="Median" value={sample.likingMedian} />
                    <LabelledValue
                      label="Delta"
                      value={sub(sample.likingMedian, sample.likingMean)}
                    />
                    <LabelledValue
                      suffix="% reporting"
                      decimals={1}
                      value={percent(sample.likingEntries, sample.tasters)}
                    />
                  </TableCell>
                  <TableCell>
                    <LabelledValue label="Mean" value={sample.conceptFitMean} />
                    <LabelledValue
                      label="Median"
                      value={sample.conceptFitMedian}
                    />
                    <LabelledValue
                      label="Delta"
                      value={sub(
                        sample.conceptFitMedian,
                        sample.conceptFitMean
                      )}
                    />
                    <LabelledValue
                      suffix="% reporting"
                      decimals={1}
                      value={percent(sample.conceptFitEntries, sample.tasters)}
                    />
                  </TableCell>
                  <TableCell>
                    <LabelledValue
                      label="# JAR"
                      value={sample.justAboutRightCount}
                      decimals={0}
                    />
                    <LabelledValue
                      label="# JAR Possible"
                      value={sample.justAboutRightPossibleCount}
                      decimals={0}
                    />
                    <LabelledValue
                      label="Percent"
                      value={sample.justAboutRightPercent}
                      decimals={1}
                      suffix="%"
                    />
                  </TableCell>
                  <TableCell>
                    <LabelledValue value={sample.tasters} decimals={0} />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
