import { css } from "@emotion/react";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import React from "react";
import StarRatingComponent from "react-star-rating-component";
import DescriptorRatingGroup from "./DescriptorRatingGroup";
import StructuredFeedback from "./StructuredFeedback";

export interface FeedbackTableProps {
  feedback: StructuredFeedback[];
}

export function FeedbackTable(p: FeedbackTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Descriptors</TableCell>
            <TableCell>Evaluation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {p.feedback.map((feedback) => (
            <TableRow key={feedback.author.id}>
              <TableCell css={css`min-width: 150px;`}>
                <Typography>{feedback.author.name}</Typography>
              </TableCell>
              <TableCell>
                <div>
                  <DescriptorRatingGroup
                    groupName="Too strong"
                    descriptorNames={feedback.ratings
                      .filter((r) => r.score === 3)
                      .map((r) => r.descriptor.name)}
                    score={3}
                  />
                  <DescriptorRatingGroup
                    groupName="Just right"
                    descriptorNames={feedback.ratings
                      .filter((r) => r.score === 2)
                      .map((r) => r.descriptor.name)}
                    score={2}
                  />
                  <DescriptorRatingGroup
                    groupName="Too weak"
                    descriptorNames={feedback.ratings
                      .filter((r) => r.score === 1)
                      .map((r) => r.descriptor.name)}
                    score={1}
                  />
                  <DescriptorRatingGroup
                    groupName="Not present"
                    descriptorNames={feedback.ratings
                      .filter((r) => r.score === 0)
                      .map((r) => r.descriptor.name)}
                    score={0}
                  />
                </div>
              </TableCell>
              <TableCell css={css`min-width: 200px;`}>
                <Box marginTop="5px" marginBottom="5px">
                  <Typography variant="h6">Liking</Typography>
                  {feedback.likingScore != null ? (
                    <StarRatingComponent
                      name={`liking${feedback.author.id}`}
                      value={feedback.likingScore!}
                      starCount={10}
                    />
                  ) : (
                    <Typography variant="subtitle2">Not rated</Typography>
                  )}
                </Box>
                <Box marginTop="5px" marginBottom="5px">
                  <Typography variant="h6">Concept Fit</Typography>
                  {feedback.conceptFitScore != null ? (
                    <StarRatingComponent
                      name={`liking${feedback.author.id}`}
                      value={feedback.conceptFitScore!}
                      starCount={5}
                    />
                  ) : (
                    <Typography variant="subtitle2">Not rated</Typography>
                  )}
                </Box>
                <Box marginTop="5px" marginBottom="5px">
                  <Typography variant="h6">Comments</Typography>
                  {feedback.comments ? (
                    <Typography>
                      {feedback.comments}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2">No comments</Typography>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export function FeedbackTablePlacehHolder() {
  return <Paper>Loading</Paper>
}