import React from "react";
import { ApolloLoadable } from "./ApolloLoadable";
import { ApolloError } from "@apollo/client";


interface ApolloRouterProps<T> {
  loadable: ApolloLoadable<T>;
  ready: (data: T) => JSX.Element;
  loading?: () => JSX.Element;
  error?: (error: ApolloError) => JSX.Element;
}

function DefaultLoadingHandler() {
  return <div>Loading...</div>
}

function DefaultErrorHandler(error: ApolloError) {
  return <div>{error.name}: {error.message}</div>
}

export default function ApolloRouter<T>(p: ApolloRouterProps<T>) {
  if (p.loadable.data) {
    return p.ready(p.loadable.data);
  } else if (p.loadable.loading) {
    return (p.loading || DefaultLoadingHandler)();
  } else if (p.loadable.error) {
    return (p.error || DefaultErrorHandler)(p.loadable.error);
  } else {
    return <div>no data</div>;
  }
}
