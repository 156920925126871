import React from "react";
import { css } from "@emotion/react";
import EditSample from "./EditSample";
import Tabs, { TabPage } from "./Tabs";
import { useQuery } from "@apollo/client";
import {
  GetSampleDetails,
  GetSampleDetailsResponse,
  GetSampleDetailsVariables,
} from "../projects/queries";
import StructuredFeedback from "./feedback/StructuredFeedback";
import { FeedbackTab } from "./feedback/FeedbackTab";
import ReportTab from "./report/ReportTab";
import {
  SampleDescriptorRatingsSummaryData,
  useSampleDescriptorRatingsSummary,
} from "../../queries/SampleDescriptorRatingsSummary";
import { DescriptorRowData } from "./report/DescriptorBarChart";
import { mapApollo } from "../../../util/ApolloLoadable";
import { DescriptorPresenceEntry } from "./report/DescriptorPresenceTable";
import _ from "lodash"
import { ReportHeaderData } from "./report/ReportHeader";
import { Word } from "react-wordcloud";
import { useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";

const styles = {
  container: css``,
};

interface SamplePageProps {
  id: string;
  projectId: string;
}

export default function SamplePage(p: SamplePageProps) {
  // Process data
  const { data, loading, error } = useQuery<
    GetSampleDetailsResponse,
    GetSampleDetailsVariables
  >(GetSampleDetails, {
    variables: { id: p.id },
  });
  const sampleDescriptorRatingsSummary = useSampleDescriptorRatingsSummary({
    variables: { sample_id: p.id },
  });
  const feedback = getFeedbackFromResponse(data);

  // Tab Handling
  const history = useHistory();
  const match = useRouteMatch<{sessionId: string; sampleId: string; tab: string}>('/admin/sessions/:sessionId/samples/:sampleId/:tab?');
  const handleTabChange = (newTab: string) => {
    history.replace(`/admin/sessions/${match?.params.sessionId}/samples/${match?.params.sampleId}/${newTab}`)
  }

  function getTabDisplay() {
    switch (match?.params.tab) {
      case "report":
        return (
          <ReportTab
            header={mapApollo(sampleDescriptorRatingsSummary, getHeaderData)}
            descriptorBars={mapApollo(sampleDescriptorRatingsSummary, getDescriptorBarData)}
            descriptorPresence={mapApollo(sampleDescriptorRatingsSummary, getDescriptorRowData)}
            words={mapApollo(sampleDescriptorRatingsSummary, getWordCloudData)}
          />
        );
      case "feedback":
        return (
          <FeedbackTab feedback={feedback} loading={loading} error={error} />
        );
      case "overview":
      default:
        return <EditSample id={p.id} projectId={p.projectId} />;
    }
  }

  if (match?.params.sampleId === 'new') {
    return <EditSample id={p.id} projectId={p.projectId} />;
  }

  return (
    <div css={styles.container}>
      <Tabs value={(match?.params.tab || "overview") as TabPage} onChange={(e, value) => handleTabChange(value)} />
      {getTabDisplay()}
    </div>
  );
}

function getHeaderData(
  data?: SampleDescriptorRatingsSummaryData  
): ReportHeaderData | undefined {
  if (data?.sample_by_pk?.evaluation_summary == null) {
    return;
  }
  return {
    name: data.sample_by_pk.name,
    sessionName: data.sample_by_pk.session.name,
    liking: {
      score: data.sample_by_pk.evaluation_summary.liking_mean,
      rank: data.sample_by_pk.evaluation_summary.liking_rank
    },
    conceptFit: {
      score: data.sample_by_pk.evaluation_summary.concept_fit_mean,
      rank: data.sample_by_pk.evaluation_summary.concept_fit_rank
    },
    profile: {
      score: data.sample_by_pk.evaluation_summary.just_about_right_percent,
      rank: data.sample_by_pk.evaluation_summary.just_about_right_rank
    },
    tasters: data.sample_by_pk.evaluation_descriptor_summary[0]?.tasters_count
  }
}

function getDescriptorBarData(
  data?: SampleDescriptorRatingsSummaryData
): DescriptorRowData[] | undefined {
  if (data?.sample_by_pk?.evaluation_descriptor_summary == null) {
    return;
  }
  return data.sample_by_pk.evaluation_descriptor_summary.map((s) => ({
    label: s.descriptor.name,
    counts: {
      0: s.score_0_count,
      1: s.score_1_count,
      2: s.score_2_count,
      3: s.score_3_count,
    },
  }));
}

function getDescriptorRowData(data?: SampleDescriptorRatingsSummaryData): DescriptorPresenceEntry[] | undefined {
  if (data?.sample_by_pk?.evaluation_descriptor_summary == null) {
    return;
  }
  return _.sortBy(data.sample_by_pk.evaluation_descriptor_summary.map(e => ({
    name: e.descriptor.name,
    times_not_present: e.score_0_count,
    times_tasted: e.tasters_count
  })), summary => summary.name);
}

function getWordCloudData(data?: SampleDescriptorRatingsSummaryData): Word[] | undefined {
  if (data?.sample_by_pk?.evaluation_word_cloud_summary == null) {
    return;
  }
  return data.sample_by_pk.evaluation_word_cloud_summary.map(word => ({
    text: word.word,
    value: word.count
  }))
}

function getFeedbackFromResponse(data?: GetSampleDetailsResponse) {
  if (data?.sample_by_pk?.evaluations == null) {
    return;
  }
  return data.sample_by_pk.evaluations.map((e) => {
    return {
      author: {
        id: e.author.id,
        name: e.author.display_name,
      },
      ratings: data.sample_by_pk.descriptors.map((desc) => {
        const matchingRating = e.descriptor_evaluations.find(
          (candidate) => candidate.descriptor_id === desc.id
        );
        return {
          descriptor: {
            id: desc.id,
            name: desc.name,
          },
          score: matchingRating?.rating || 0,
        };
      }),
      likingScore: e.liking_score,
      conceptFitScore: e.concept_fit_score,
      comments: e.comments,
    };
  }) as StructuredFeedback[];
}