import React from "react";
import { css } from "@emotion/react";
import ObjectSummary from "./ObjectSummary";
import { Card, CardContent } from "@material-ui/core";
import DataDownload from "./DataDownload";

const styles = {
  container: css`
    display: grid;
    grid-template-columns: minmax(400px, 1fr) minmax(400px, 1fr);
    gap: 1em 1em;
  `,
};

interface ExportProps {}

export default function Export(p: ExportProps) {
  return (
    <div css={styles.container}>
      <Card>
        <CardContent>
          <ObjectSummary />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <DataDownload/>
        </CardContent>
      </Card>
    </div>
  );
}
