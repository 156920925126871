import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const SURVEY_RESULTS_QUERY = gql`
  query SurveyResponseCsv($survey_id: uuid!) {
    SUR_survey_by_pk(id: $survey_id) {
      id
      responses {
        id
        created_at
        question_responses {
          id
          question_id
          value
        }
      }
      questions {
        id
        name
      }
    }
  }
`;

export interface SurveyResultsData {
  SUR_survey_by_pk: {
    id: string;
    responses: {
      id: string;
      created_at: string;
      question_responses: {
        id: string;
        question_id: string;
        value: string | null;
      }[];
    }[];
    questions: {
      id: string;
      name: string;
    }[];
  };
}

export interface SurveyResultsVariables {
  survey_id: string;
}

export function useSurveyResultsQuery(
  options?:
    | QueryHookOptions<SurveyResultsData, SurveyResultsVariables>
    | undefined
) {
  return useQuery<SurveyResultsData, SurveyResultsVariables>(
    SURVEY_RESULTS_QUERY,
    options
  );
}
