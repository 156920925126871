import React from "react";
import { css } from "@emotion/react";
import { Button, LinearProgress, Typography } from "@material-ui/core";
import useDataExport from "./queries/DataExport";
import { Alert } from "@material-ui/lab";
import triggerDownload from "js-file-download";


const styles = {
  container: css`
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: 1em 1em;
  `,
  header: css``,
};

interface DataDownloadProps {}

export default function DataDownload(p: DataDownloadProps) {
  const { data, error, loading } = useDataExport();

  const handleDownloadClick = () => {
    triggerDownload(data?.data_export_csv?.csv!, "sample_feedback.csv", 'text/csv')
  }

  return (
    <div css={styles.container}>
      <Typography css={styles.header} variant="h5">
        Export as CSV
      </Typography>
      <div />
      {error ? (
        <Alert severity="error">
          <Typography variant="h6">Could not prepare CSV</Typography>
          <Typography variant="body1">{error.name}: {error.message}</Typography>
        </Alert>
      ) : loading ? (
        <LinearProgress/>
      ) : (
        <Button onClick={handleDownloadClick} disabled={!data?.data_export_csv} variant="contained">
          Download Sample Feedback CSV
        </Button>
      )}
    </div>
  );
}
