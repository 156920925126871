import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import EditDescriptorHOC from "./pages/descriptors/details/EditDescriptorHOC";
import DescriptorList from "./pages/descriptors/list";
import Export from "./pages/export/Export";
import { Label } from "./pages/labels/label";
import { PrintLabels } from "./pages/labels/PrintLabels";
import EditLexicon from "./pages/lexicons/EditLexicon";
import LexiconList from "./pages/lexicons/LexiconList";
import ProjectList from "./pages/projects/ProjectList";
import UserList from "./pages/users/UserList";
import SamplePage from "./pages/samples/SamplePage";
import { SessionPage } from "./pages/projects/SessionPage";
import CreateUser from "./pages/users/CreateUser";
import SurveyList from "./pages/surveys/SurveyList";
import CreateSurvey from "./pages/surveys/CreateSurvey";
import ManageSurveyPage from "./pages/surveys/ManageSurveyPage";


export default function Routes() {
  return (
    <Switch>
      <Route
        path="/admin/descriptors/:descriptorId"
        exact={false}
        strict={false}
        render={(p) => (
          <EditDescriptorHOC id={p.match.params["descriptorId"]} />
        )}
      />
      <Route
        path="/admin/descriptors"
        exact={false}
        strict={false}
        render={(p) => <DescriptorList editingId={""} />}
      />
      <Route
        path="/admin/lexicons/:lexiconId"
        exact={false}
        strict={false}
        render={(p) => <EditLexicon id={p.match.params["lexiconId"]} />}
      />
      <Route
        path="/admin/lexicons"
        exact={false}
        strict={false}
        render={(p) => <LexiconList />}
      />
      <Route
        path="/admin/sessions/:projectId/samples/:sampleId"
        render={(p) => (
          <SamplePage
            projectId={p.match.params["projectId"]}
            id={p.match.params["sampleId"]}
          />
        )}
      />
      <Route
        path="/admin/sessions/:sessionId"
        render={(p) => <SessionPage sessionId={p.match.params["sessionId"]} />}
      />
      <Route path="/admin/export" render={(p) => <Export />} />
      <Route path="/admin/sessions" render={(p) => <ProjectList />} />
      <Route path="/admin/users/new" render={(p) => <CreateUser/>} />
      <Route path="/admin/users" render={(p) => <UserList />} />
      <Route path="/admin/surveys/new" render={(p) => <CreateSurvey />} />
      <Route path="/admin/surveys/:surveyId" render={(p) => <ManageSurveyPage surveyId={p.match.params["surveyId"]}/>} />
      <Route path="/admin/surveys" render={(p) => <SurveyList />} />
      <Route
        path="/admin/labels"
        render={(p) => <PrintLabels labels={p.location.state as Label[]} />}
      />
      <Route
        path="/admin"
        render={(p) => <Redirect to="/admin/descriptors/" />}
      />
    </Switch>
  );
}
