import React from "react";
import { css } from "@emotion/react";
import { Button, Typography } from "@material-ui/core";

const styles = {
  container: css``,
  heading: css`
    display: flex;
    align-items: center;
  `,
  headingText: css`
    flex: 1 1 auto;
  `,
  printButtonContainer: css`
    @media print
    {    
      display: none !important;
    }
  `
};

export interface ReportHeaderData {
  name: string;
}

export interface ReportHeaderProps extends ReportHeaderData {
  onPrint: () => void;
}

export function ReportHeader(p: ReportHeaderProps) {
  return (
    <div css={styles.container}>
      <div css={styles.heading}>
        <div css={styles.headingText}>
          <Typography variant="h2">
            <i>{p.name}</i> Report
          </Typography>
        </div>
        <div css={styles.printButtonContainer}>
          <Button variant="contained" onClick={e => p.onPrint()}>Print Report</Button>
        </div>
      </div>
    </div>
  );
}
