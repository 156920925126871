import React from "react";
import { css } from "@emotion/react";

const styles ={
  container: css``,
}

interface ErrorHandlerProps {

}

export function ErrorHandler(p: ErrorHandlerProps) {
  return (<div css={styles.container}>An error has occurred</div>);
}
