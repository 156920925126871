import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  ProjectSummaryTable,
  ProjectSummaryTableProps,
} from "../../../../components/ProjectSummaryTable/ProjectSummaryTable";
import { ApolloLoadable } from "../../../../util/ApolloLoadable";
import ApolloRouter from "../../../../util/ApolloRouter";
import { Card, CardContent, Typography } from "@material-ui/core";
import ShareToggle from "./ShareToggle";
import { ReportHeader } from "./ReportHeader";
import NewWindow from "react-new-window";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import DescriptorTable, { DescriptorStats } from "./DescriptorTable";
import SampleOverviewTable, { SampleDatum } from "./SampleOverviewTable";

const styles = {
  container: css`
    background-color: #eeeeee;
    @media print {
      background-color: #ffffff !important;
      padding: 0px !important;
      margin: 0px !important;
    }
  `,
};

export interface ProjectReportData {
  name: string;
  evaluatorCount: number;
  shareTable: boolean;
  tableData: ProjectSummaryTableProps;
  descriptorStats: DescriptorStats[];
  sampleOverviewData: SampleDatum[];
}

interface ProjectReportProps {
  data: ApolloLoadable<ProjectReportData | null>;
  onTableShareChange: (newValue: boolean) => void;
}

export default function ReportTab(p: ProjectReportProps) {
  const [printMode, setPrintMode] = useState(false);

  const handlePrint = () => {
    setPrintMode(true);
  };

  const handlePrintClosed = () => {
    setPrintMode(false);
  };

  const out = (
    <div css={styles.container}>
      <ApolloRouter
        loadable={p.data}
        ready={(data) =>
          data === null ? (
            <Typography>Project does not exist</Typography>
          ) : (
            <div>
              <div
                css={css`
                  padding: 5px;
                  margin-bottom: 10px;
                `}
              >
                <ReportHeader name={data.name} onPrint={handlePrint} />
              </div>
              <div
                css={css`
                  padding: 5px;
                `}
              >
                <Card>
                  <CardContent
                    css={css`
                      display: flex;
                    `}
                  >
                    <Typography
                      css={css`
                        flex-grow: 1;
                      `}
                    >
                      Results Summary
                    </Typography>
                    <ShareToggle
                      evaluators={data.evaluatorCount}
                      checked={data.shareTable}
                      onChange={(_, share) => {
                        p.onTableShareChange(share);
                      }}
                    />
                  </CardContent>
                  <ProjectSummaryTable {...data.tableData} showLinks />
                </Card>
              </div>
              <div
                css={css`
                  padding: 5px;
                  @media print {
                    page-break-before: always;
                  }
                `}
              >
                <DescriptorTable samples={data.descriptorStats} />
              </div>
              <div
                css={css`
                  padding: 5px;
                  @media print {
                    page-break-before: always;
                  }
                `}
              >
                <SampleOverviewTable samples={data.sampleOverviewData} />
              </div>
            </div>
          )
        }
      />
    </div>
  );

  if (printMode) {
    return (
      <NewWindow
        onOpen={(win) =>
          setTimeout(() => {
            win.print();
            win.close();
          }, 5000)
        }
        onUnload={handlePrintClosed}
        center="screen"
        copyStyles
      >
        <div
          css={css`
            height: 100vh;
            width: 100vw;
            z-index: 99999;
            background-color: rgb(0, 0, 0, 0.5);
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @media print {
              display: none;
            }
          `}
        >
          <Typography variant="h5">Preparing to print...</Typography>
          <FontAwesomeIcon icon={faPrint} size="4x" />
        </div>
        {out}
      </NewWindow>
    );
  } else {
    return out;
  }
}
