import React from "react";
import { css } from "@emotion/react";
import { Typography } from "@material-ui/core";

const styles = {
  container: css`
    padding: 0px 20px 20px 20px;
  `,
};

interface FamilyProps {
  family: string;
}

export default function Family(p: FamilyProps) {
  return (
    <div css={styles.container}>
      <Typography align="center" variant="h6">
        Family
      </Typography>
      <Typography align="center" variant="body1">
        {p.family}
      </Typography>
    </div>
  );
}