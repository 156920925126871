import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import QRCode from "../../components/QRCode";

export interface SurveyQRCodePrinterProps {
  surveyId: string;
  surveyName: string;
  qrCodes: { id: string; code: string }[];
}

export default function SurveyQRCodePrinter(p: SurveyQRCodePrinterProps) {
  const history = useHistory();
  const surveyUrl = `givaudanvisa.com/q/${p.qrCodes[0].code}`;

  const displayMessage = (message: string) => {
    console.log(message);
  };

  const handleCopyUrlClick = () => {
    navigator.clipboard.writeText(surveyUrl).then(
      () => {
        displayMessage("Copied!");
      },
      (err) => {
        console.error("Async: Could not copy text: ", err);
        displayMessage("Failed to copy");
      }
    );
  };

  const handlePrint = () => {
    const data = [
      {
        type: "survey",
        caption: p.surveyName,
        qrCode: p.qrCodes[0].code,
      },
    ];
    history.push("/admin/labels", data);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <TextField
        label="Shareable Link"
        // className={clsx(classes.margin, classes.textField)}
        contentEditable={false}
        value={surveyUrl}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleCopyUrlClick}>
                <FontAwesomeIcon icon={faCopy} fixedWidth size="sm" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <QRCode id={p.qrCodes.map((q) => q.id)} size={150} />
      <Button onClick={handlePrint}>Print label sheet</Button>
    </Box>
  );
}
