import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const LIST_SURVEYS_QUERY = gql`
  query ListSurveys {
    SUR_survey {
      id
      internal_name
      name
      responses_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export interface ListSurveysData {
  SUR_survey: {
    id: string;
    internal_name: string;
    name: string;
    responses_aggregate: {
      aggregate: {
        count: number;
      }
    }
  }[]
}

export interface ListSurveysVariables {
}

export function useListSurveysQuery(
  options?:
    | QueryHookOptions<
        ListSurveysData,
        ListSurveysVariables
      >
    | undefined
) {
  return useQuery<
    ListSurveysData,
    ListSurveysVariables
  >(LIST_SURVEYS_QUERY, options);
}
