import React from "react";
import { Card, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";

export interface DescriptorPresenceEntry {
  name: string;
  times_not_present: number;
  times_tasted: number;
}

export interface DescriptorPresenceTableProps {
  descriptorEntries: DescriptorPresenceEntry[];
}

export default function DescriptorPresenceTable(p: DescriptorPresenceTableProps) {
  return (
    <Card>
      <CardContent>
        <Typography>Descriptor Presence</Typography>
      </CardContent>
      <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Descriptor</TableCell>
            <TableCell align="right"># Rated Not Present</TableCell>
            <TableCell align="right"># Tasters</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {p.descriptorEntries.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.times_not_present}</TableCell>
              <TableCell align="right">{row.times_tasted}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer></Card>
  );
}
