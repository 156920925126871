import { css } from "@emotion/react";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import React from "react";

const styles = {
  nullResult: css`
    color: gray !important;
  `
}

export interface Question {
  questionId: string;
  text: string;
}

export interface Response {
  responseId: string;
  date: Date;
  answers: Answer[];
}

export interface Answer {
  answerId: string;
  questionId: string;
  value: string | null;
}

export interface ResultsTableProps {
  surveyId: string;
  questions: Question[];
  responses: Response[];
}

export default function ResultsTable(p: ResultsTableProps) {
  return <TableContainer component={Paper}>
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>Date</TableCell>
        {p.questions.map(q => (<TableCell key={q.questionId}>{q.text}</TableCell>))}
      </TableRow>
    </TableHead>
    <TableBody>
      {p.responses.map(response => (<TableRow key={response.responseId}>
        <TableCell>{response.date.toLocaleString()}</TableCell>
        {p.questions.map(q => {
          const answer = response.answers.find(answer => answer.questionId === q.questionId);
          if (answer?.value != null) {
            if (answer.value.includes('|')) {
              const individualAnswers = answer.value.split('|');
              return <TableCell>
                <ul>
                  {individualAnswers.map((a, idx) => <li key={idx}>{a}</li>)}
                </ul>
              </TableCell>
            }
            return <TableCell>{answer.value}</TableCell>
          } else {
            return <TableCell css={styles.nullResult}>-</TableCell>
          }
        })}
      </TableRow>))}
    </TableBody>
  </Table>
</TableContainer>
}