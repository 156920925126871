import React from "react";
import { css } from "@emotion/react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ListLexicons, ListLexiconsResponse } from "./queries";
import { useQuery } from "@apollo/client";
import LoadableImage from "../components/LoadableImage";
import QRCode from "../components/QRCode";

const styles = {
  main: css`
    height: calc(100vh - 160px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 1em 1em;
    grid-template-areas: "table";
  `,
};

const LexiconList = () => {
  const history = useHistory();
  const { data } = useQuery<ListLexiconsResponse, {}>(ListLexicons, {pollInterval: 2000});

  const handleCreateNewClicked = () => {
    history.push(`/admin/lexicons/new`);
  };

  return (
    <div css={styles.main}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Lexicon</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>QR Code</TableCell>
              <TableCell
                css={css`
                  min-width: 100px;
                `}
              >
                <Button
                  css={css`
                    width: 100px;
                  `}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleCreateNewClicked}
                >
                  Create
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          {data ? (
            <TableBody>
              {data.lexicon.map((l) => (
                <TableRow
                  key={l.id}
                  onDoubleClick={() => {
                    history.push(`/admin/lexicons/${l.id}`);
                  }}
                >
                  <TableCell>{l.name}</TableCell>
                  <TableCell>
                    <LoadableImage variant="icon" url={l.image} />
                  </TableCell>
                  <TableCell>{l.description}</TableCell>
                  <TableCell>
                    {l.lexicon_descriptors_aggregate.aggregate.count}
                  </TableCell>
                  <TableCell>
                    <QRCode id={l.qr_codes.map((qr) => qr.id)} size={75} />
                  </TableCell>
                  <TableCell>
                    <Button
                                    css={css`width: 100px;`}
                      size="small"
                      variant="contained"
                      onClick={() => {
                        history.push(`/admin/lexicons/${l.id}`);
                      }}
                    >
                      Manage
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <div>Loading</div>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default LexiconList;
