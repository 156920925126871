import React from "react";
import { css } from "@emotion/react";
import { FeedbackTable, FeedbackTablePlacehHolder } from "./FeedbackTable";
import StructuredFeedback from "./StructuredFeedback";
import { ErrorHandler } from "../../components/ErrorHandler";

const styles = {
  container: css``,
};

interface FeedbackTabProps {
  loading: boolean;
  feedback?: StructuredFeedback[];
  error?: any;
}

export function FeedbackTab(p: FeedbackTabProps) {
  function getPrimaryContent() {
    if (p.loading) {
      return <FeedbackTablePlacehHolder />;
    } else if (p.error || p.feedback == null) {
      return <ErrorHandler />;
    } else {
      return <FeedbackTable feedback={p.feedback} />;
    }
  }
  return <div css={styles.container}>{getPrimaryContent()}</div>;
}
