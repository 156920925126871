import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const sampleEvaluationSummaryQuery = gql`
  query SampleEvaluationSummary($sample_id: uuid!) {
    sample_by_pk(id: $sample_id) {
      id
      name
      session {
        id
        name
      }
      evaluation_summary {
        liking_mean
        liking_rank
        concept_fit_mean
        concept_fit_rank
        just_about_right_percent
        just_about_right_rank
      }
      evaluation_descriptor_summary {
        descriptor {
          id
          name
        }
        present_count
        present_percent
        score_0_count
        score_0_percent_of_tasters
        score_1_count
        score_1_percent_of_present
        score_1_percent_of_tasters
        score_2_count
        score_2_percent_of_present
        score_2_percent_of_tasters
        score_3_count
        score_3_percent_of_present
        score_3_percent_of_tasters
        tasters_count
      }
      evaluation_word_cloud_summary {
        word
        count
      }
    }
  }
`;

export interface SampleDescriptorRatingsSummaryVariables {
  sample_id: string;
}

export interface SampleDescriptorRatingsSummaryData {
  sample_by_pk?: {
    id: string;
    name: string;
    session: {
      id: string;
      name: string;
    };
    evaluation_summary: {
      liking_mean?: number;
      liking_rank?: number;
      concept_fit_mean?: number;
      concept_fit_rank?: number;
      just_about_right_percent?: number;
      just_about_right_rank?: number;
    };
    evaluation_descriptor_summary: {
      descriptor: {
        id: string;
        name: string;
      };
      present_count: number;
      present_percent?: number;
      score_0_count: number;
      score_0_percent_of_tasters?: number;
      score_1_count: number;
      score_1_percent_of_present?: number;
      score_1_percent_of_tasters?: number;
      score_2_count: number;
      score_2_percent_of_present?: number;
      score_2_percent_of_tasters?: number;
      score_3_count: number;
      score_3_percent_of_present?: number;
      score_3_percent_of_tasters?: number;
      tasters_count: number;
    }[];
    evaluation_word_cloud_summary: {
      word: string;
      count: number;
    }[];
  };
}

export function useSampleDescriptorRatingsSummary(
  options?:
    | QueryHookOptions<
        SampleDescriptorRatingsSummaryData,
        SampleDescriptorRatingsSummaryVariables
      >
    | undefined
) {
  return useQuery<
    SampleDescriptorRatingsSummaryData,
    SampleDescriptorRatingsSummaryVariables
  >(sampleEvaluationSummaryQuery, options);
}
