import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateDescriptor,
  CreateDescriptorResponse,
  CreateDescriptorVariables,
  QueryDescriptorDetails,
  QueryDescriptorDetailsResponse,
  QueryDescriptorDetailsVariables,
  UpdateDescriptor,
  UpdateDescriptorResponse,
  UpdateDescriptorVariables,
} from "./queries";
import EditDescriptor, { Descriptor } from "./EditDescriptor";
import { useHistory } from "react-router-dom";

interface EditDescriptorHOCProps {
  id: string;
}

const EditDescriptorHOC = (p: EditDescriptorHOCProps) => {
  const history = useHistory();
  const isNew = !p.id || p.id === "new";
  const { data } = useQuery<
    QueryDescriptorDetailsResponse,
    QueryDescriptorDetailsVariables
  >(QueryDescriptorDetails, { variables: { id: p.id }, skip: isNew });
  const [updateDescriptor] = useMutation<
    UpdateDescriptorResponse,
    UpdateDescriptorVariables
  >(UpdateDescriptor, {refetchQueries: ["GetAdminPanelDescriptors"]});
  const [createDescriptor] = useMutation<
    CreateDescriptorResponse,
    CreateDescriptorVariables
  >(CreateDescriptor, {refetchQueries: ["GetAdminPanelDescriptors"]});

  const handleCancel = () => {
    history.replace("/admin/descriptors");
  };
  const handleSave = (descriptor: Descriptor) => {
    if (isNew) {
      createDescriptor({
        variables: {
          name: descriptor.name,
          description: descriptor.description,
          image: descriptor.image,
          color_value: descriptor.color,
          color_name: descriptor.colorName,
          evaluation_instruction: descriptor.instruction,
          family: descriptor.family,
        },
      }).then(handleCancel);
    } else {
      updateDescriptor({
        variables: {
          id: descriptor.id,
          name: descriptor.name,
          description: descriptor.description,
          image: descriptor.image,
          color_value: descriptor.color,
          color_name: descriptor.colorName,
          evaluation_instruction: descriptor.instruction,
          family: descriptor.family,
        },
      }).then(handleCancel);
    }
  };

  if (data) {
    const descriptor = {
      id: data.descriptor_by_pk.id,
      name: data.descriptor_by_pk.name,
      description: data.descriptor_by_pk.description,
      image: data.descriptor_by_pk.image,
      color: data.descriptor_by_pk.color_value,
      colorName: data.descriptor_by_pk.color_name,
      instruction: data.descriptor_by_pk.evaluation_instruction,
      family: data.descriptor_by_pk.family,
      qrCodeId: data.descriptor_by_pk.qr_codes.map((q) => q.id),
    } as Descriptor;
    const feedback = data.descriptor_by_pk.ratings.map(ev => ({
      author: {
        id: ev.appUser.id,
        name: ev.appUser.display_name
      },
      score: ev.rating,
      comments: ev.comments
    }))
    return (
      <EditDescriptor
        descriptor={descriptor}
        feedback={feedback}
        onCancel={handleCancel}
        onSave={handleSave}
      />
    );
  } else if (isNew) {
    const descriptor = {
      id: "new",
      name: "New Descriptor",
      description: "Enter some text describing the descriptor",
      image: "",
      color: "#4455FF",
      colorName: "Fancy Blue",
      family: "",
      instruction: "sniff",
      qrCodeId: []
    }
    return (
      <EditDescriptor
        descriptor={descriptor}
        onCancel={handleCancel}
        onSave={handleSave}
      />
    );
  } else {
    return <div>loading</div>;
  }
};

export default EditDescriptorHOC;
