import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import internal from 'events';
import React, { useEffect, useState } from 'react';
import { QuestionOption } from './Options';
import { QuestionCardDataProps } from './QuestionCard';

interface LogicProps{
    questions: QuestionCardDataProps[];
    parent: string;
    logical_value: string;
    question_index: number;
    onParentChange: (newQuestion: string | "")=> void;
    onLogicChange: (newLogic: string | "") => void;
}

export default function EnableLogic(p:LogicProps){
    const [question, setQuestion] = React.useState<string>("");
    const [option,setOption] = React.useState<string>(p.logical_value);
    const [parentIndex, setParentIndex] = React.useState<number>(0);

    useEffect(()=>{
        p.questions.map((q,pos)=>{
            if(q.id === p.parent){  
                setQuestion(String(q.index));
            }
        })
        
    },[])
    
    function handleQuestionSelect(val: string){
        console.log(question)
        if(val !== ""){
            setQuestion(val);
            p.onParentChange(p.questions[Number(val)].id)
            console.log(val)
        }else{
            setQuestion("");
            p.onParentChange("")
            console.log(val)
        }
        
    }
    function handleLogicSelect(val: string){
        p.onLogicChange(val)
        setOption(val)
    }
    const selectStyleParent = {
        paddingTop: '2%',
        
    }

    //TODO: Fix the layout of the parent selection boxes
    return(
        <Box display='flex' flexDirection="row" style={selectStyleParent}>
            <FormControl variant='outlined' style={{paddingRight: '2%'}}>
                <InputLabel style={{paddingBottom: '10%'}}>Parent</InputLabel>
                <Select
                value = {question}               
                onChange = {(e)=>handleQuestionSelect(String(e.target.value))}>
                    {p.questions.map((questions,index)=>(
                        p.question_index !== questions.index && questions.type === "select_radio" ? (
                            <MenuItem value = {questions.index}>Question: {questions.index + 1}</MenuItem>
                        ): null
                        
                    ))}
                    <MenuItem value = "">None</MenuItem>
                </Select>
            </FormControl>
            {
            question !== "" ? (
                <div>
                    <FormControl variant='outlined' >
                        <InputLabel>Logical Value</InputLabel>
                        <Select
                            value = {option}
                            onChange = {(e)=>handleLogicSelect(String(e.target.value))}>
                                {p.questions[Number(question)].options.map((option,index)=>(
                                    <MenuItem value = {String(option.text)}>{String(option.text)}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </div>
            ): null
            }
        </Box>
        
    );
}