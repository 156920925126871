import { gql, useQuery } from "@apollo/client";

export const DataExportQuery = gql`
query data_export {
  data_export_csv {
    csv
  }
}
`;

export interface DataExportData {
  data_export_csv: {
    csv: string;
  }
}

export default function useDataExport() {
  return useQuery<DataExportData>(DataExportQuery, {fetchPolicy: "network-only", pollInterval: 2000});
}