import React from "react";
import { css } from "@emotion/react";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

const styles = {
  fallback: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `
};

interface DescriptorFeedbackProps {
  feedback: {
    author: {
      id: string;
      name: string;
    };
    score: number;
    comments: string;
  }[];
}

const DescriptorFeedback = (p: DescriptorFeedbackProps) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Score</TableCell>
            <TableCell>Comments</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {p.feedback.map((feedback) => (
            <TableRow key={feedback.author.id}>
              <TableCell>
                <Typography>{feedback.author.name}</Typography>
              </TableCell>
              <TableCell>{feedback.score}</TableCell>
              <TableCell>
                <Typography variant="body1">{feedback.comments}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const DescriptorFeedbackFallback = () => {
  return <Paper css={styles.fallback}>
    <CircularProgress/>
  </Paper>
}

export default DescriptorFeedback;

