import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { Card, CardContent, Typography } from "@material-ui/core";
import _ from "lodash";

function getChartData(descriptorData: DescriptorRowData[]) {
  const labels = getLabels(descriptorData);
  const percents = getPercents(descriptorData);

  return {
    labels: labels,
    datasets: [
      {
        label: "Too Weak",
        backgroundColor: "rgb(241,247,214)",
        data: percents.map((p) => p[1]),
      },
      {
        label: "Just Right",
        backgroundColor: "rgb(180,212,71)",
        data: percents.map((p) => p[2]),
      },
      {
        label: "Too Strong",
        backgroundColor: "rgb(91,128,41)",
        data: percents.map((p) => p[3]),
      },
    ],
  };
}

function getLabels(descriptorData: DescriptorRowData[]) {
  return descriptorData.map((d) => d.label);
}

function getPercents(descriptorData: DescriptorRowData[]) {
  return descriptorData.map((d) => getPercent(d.counts));
}

function getPercent(counts: ScoreCounts) {
  const total = counts[1] + counts[2] + counts[3] || 1;
  return {
    1: (100 * counts[1]) / total,
    2: (100 * counts[2]) / total,
    3: (100 * counts[3]) / total,
  };
}

export interface ScoreCounts {
  1: number;
  2: number;
  3: number;
}

export interface DescriptorRowData {
  label: string;
  counts: ScoreCounts;
}

export interface DescriptorBarChartProps {
  data: DescriptorRowData[];
  hideUnrated?: boolean;
}

export default function DescriptorBarChart(p: DescriptorBarChartProps) {
  let data = _.sortBy(p.data, (d) => d.label);
  if (p.hideUnrated) {
    data = data.filter((d) => d.counts[1] + d.counts[2] + d.counts[3] > 0);
  }
  const chartData = getChartData(data);
  return (
    <Card>
      <CardContent>
        <Typography>Rating Bar Chart</Typography>
        <HorizontalBar
          data={chartData}
          height={chartData.labels.length * 20 + 80}
          options={{
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  stacked: true,
                  ticks: {
                    min: 0,
                    max: 100,// Your absolute max value
                    callback: function (value: number) {
                      return (value / this.max * 100).toFixed(0) + '%'; // convert it to percentage
                    },
                  },
                },
              ],
              yAxes: [
                {
                  stacked: true,
                },
              ],
            },
            tooltips: {
              enabled: false
            }
          }}
        />
      </CardContent>
    </Card>
  );
}
