import { gql} from "@apollo/client";

export const QueryDescriptorDetails = gql`
query GetDescriptorDetails($id: uuid!) {
  descriptor_by_pk(id: $id) {
    id
    name
    description
    image
    color_value
    color_name
    family
    evaluation_instruction
    qr_codes {
      id
    }
    ratings {
      appUser {
        id
        display_name
      }
      rating
      comments
    }
  }
}
`;

export interface QueryDescriptorDetailsVariables {
  id: string;
}

export interface QueryDescriptorDetailsResponse {
  descriptor_by_pk: {
    id: string;
    name: string;
    description: string;
    image: string;
    color_value: string;
    color_name: string;
    evaluation_instruction: string;
    family: string;
    qr_codes: {
      id: string;
    }[];
    ratings: {
      appUser: {
        id: string;
        display_name: string;
      },
      rating: number;
      comments: string;
    }[];
  }
}

export const UpdateDescriptor = gql`
  mutation UpdateDescriptor(
    $id: uuid!
    $color_name: String
    $color_value: String
    $description: String!
    $image: String = ""
    $name: String!
    $evaluation_instruction: String!
    $family: String
  ) {
    update_descriptor_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        image: $image
        color_name: $color_name
        color_value: $color_value
        description: $description
        evaluation_instruction: $evaluation_instruction
        family: $family
      }
    ) {
      id
      name
      image
      color_name
      color_value
      description
      evaluation_instruction
      family
      qr_codes {
        id
      }
    }
  }
`;

export interface UpdateDescriptorVariables {
  id: string;
  name: string;
  description: string;
  image?: string;
  color_name?: string;
  color_value?: string;
  evaluation_instruction: string;
  family: string;
}

export interface UpdateDescriptorResponse {
  update_descriptor_by_pk: {
    id: string;
    name: string;
    description: string;
    image: string;
    color_value: string;
    color_name: string;
    evaluation_instruction: string;
    family: string;
    qr_codes: {
      id: string;
    }[]
  }
}

export const CreateDescriptor = gql`
mutation CreateDescriptor($name: String!, $color_name: String = "", $color_value: String = "", $evaluation_instruction: String = "", $family: String = "",$description: String = "", $image: String = "") {
  insert_descriptor_one(object: {name: $name, image: $image, description: $description, color_name: $color_name, color_value: $color_value, evaluation_instruction: $evaluation_instruction, family: $family,qr_codes: {data: {}}}) {
    id
    name
    image
    description
    color_value
    color_name
    evaluation_instruction
    family
    qr_codes {
      id
    }
  }
}
`;

export interface CreateDescriptorVariables {
  name: string;
  description: string;
  image?: string;
  color_name?: string;
  color_value?: string;
  evaluation_instruction: string;
  family: string;
}

export interface CreateDescriptorResponse {
  insert_descriptor_one: {
    id: string;
    name: string;
    description: string;
    image: string;
    color_value: string;
    color_name: string;
    evaluation_instruction: string;
    family: string;
    qr_codes: {
      id: string;
    }[]
  }
}

export const DeleteDescriptorMutation = gql`
mutation DeleteDescriptor($descriptor_id: uuid!) {
  delete_descriptor_by_pk(id: $descriptor_id) {
    id
  }
}
`

export interface DeleteDescriptorVariables {
  descriptor_id: string;
}

export interface DeleteDescriptorData {
  delete_descriptor_by_pk: {
    id: string;
  }
}
