import { css } from "@emotion/react";
import { Box } from "@material-ui/core";
import React from "react";
import InformationCard from "./InformationCard";
import { QuestionOption } from "./Options";
import QuestionCard, { QuestionCardDataProps, QuestionCardProps, QuestionUpdate } from "./QuestionCard";

const styles = {
  container: css`
    width: 750px;
  `,
};

export interface SurveyFormDataProps {
  id: string;
  internalName: string;
  name: string;
  description: string;
  qrCodes: {id: string, code: string}[];
  questions: QuestionCardDataProps[];
}

export interface SurveyFormProps extends SurveyFormDataProps {
  onInformationChange: (internalName: string, name: string, description: string) => void;
  onUpdateQuestion: (update: QuestionUpdate) => void;
  onDeleteQuestion: (questionId: string) => void;
  onSwapQuestion: (questionAId: string, questionBId: string) => void;
  onOptionCreated: (option: QuestionOption, questionId: string) => void;
  onOptionUpdated: (option: QuestionOption) => void;
  onOptionDeleted: (optionId: string) => void;
  onQuestionCopied: (questionCopy: QuestionCardProps)=>void;
}

export default function SurveyForm(p: SurveyFormProps) {

  const handleQuestionMove = (questionIndex: number, direction: 'up' | 'down') => {
    const nextQuestion = p.questions[direction === 'up' ? questionIndex - 1 : questionIndex + 1];
    if (nextQuestion != null) {
      p.onSwapQuestion(nextQuestion.id, p.questions[questionIndex].id);
    }
  }
  
  return (
    <div css={styles.container}>
      <InformationCard
        qrCodes={p.qrCodes}
        surveyId={p.id}
        internalName={p.internalName}
        name={p.name}
        description={p.description}
        onUpdate={p.onInformationChange}
      />
      {p.questions.map((questionDataProps, index) => (
        <Box key={questionDataProps.id} marginTop={1} marginBottom={1}>
          <QuestionCard
            onQuestionUpdated={p.onUpdateQuestion}
            onQuestionDeleted={() => {p.onDeleteQuestion(questionDataProps.id)}}
            onQuestionMoved={(direction) => handleQuestionMove(index, direction)}
            onOptionCreated={o => p.onOptionCreated(o, questionDataProps.id)}
            onOptionUpdated={p.onOptionUpdated}
            onOptionDeleted={p.onOptionDeleted}
            onQuestionCopied={p.onQuestionCopied}
            questions={p.questions}
            {...questionDataProps}
          />
        </Box>
      ))}
    </div>
  );
}
