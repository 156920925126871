import React, { useState } from "react";
import { css } from "@emotion/react";
import DescriptorBarChart, { DescriptorRowData } from "./DescriptorBarChart";
import DescriptorPresenceTable, {
  DescriptorPresenceEntry,
} from "./DescriptorPresenceTable";
import { ApolloLoadable } from "../../../../util/ApolloLoadable";
import ApolloRouter from "../../../../util/ApolloRouter";
import { ReportHeader, ReportHeaderData } from "./ReportHeader";
import NewWindow from "react-new-window";
import { Word } from "react-wordcloud";
import WordCloudCard from "./WordCloudCard";
import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

const styles = {
  container: css`
    background-color: #eeeeee;
    @media print {
      background-color: #ffffff !important;
      padding: 10px;
    }
  `,
};

interface ReportTabProps {
  header: ApolloLoadable<ReportHeaderData>;
  descriptorPresence: ApolloLoadable<DescriptorPresenceEntry[]>;
  descriptorBars: ApolloLoadable<DescriptorRowData[]>;
  words: ApolloLoadable<Word[]>;
}

export default function ReportTab(p: ReportTabProps) {
  const [printMode, setPrintMode] = useState(false);

  const handlePrint = () => {
    setPrintMode(true);
  };

  const handlePrintClosed = () => {
    setPrintMode(false);
  };

  const out = (
    <div css={styles.container} id="report">
      <div
        css={css`
          padding: 5px;
        `}
      >
        <ApolloRouter
          loadable={p.header}
          ready={(data) => <ReportHeader {...data} onPrint={handlePrint} />}
        />
      </div>
      <div
        css={css`
          padding: 5px;
        `}
      >
        <ApolloRouter
          loadable={p.descriptorBars}
          ready={(data) => <DescriptorBarChart data={data} hideUnrated />}
        />
      </div>
      <div
        css={css`
          display: flex;
          @media print {
            page-break-before: always;
          }
        `}
      >
        <div css={css`flex-grow: 1; padding: 5px;`}>
        <ApolloRouter
          loadable={p.descriptorPresence}
          ready={(data) => <DescriptorPresenceTable descriptorEntries={data} />}
        />
        </div>

        <div css={css`flex-grow: 1; padding: 5px;`}>
        <ApolloRouter
          loadable={p.words}
          ready={(data) => <WordCloudCard words={data} />}
        />
        </div>
      </div>
    </div>
  );

  if (printMode) {
    return (
      <NewWindow
        onOpen={(win) =>
          setTimeout(() => {
            win.print();
            win.close();
          }, 2000)
        }
        onUnload={handlePrintClosed}
        center="screen"
        copyStyles
      >
        <div
          css={css`
            height: 100vh;
            width: 100vw;
            z-index: 99999;
            background-color: rgb(0, 0, 0, 0.5);
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @media print {
              display: none;
            }
          `}
        >
          <Typography variant="h5">Preparing to print...</Typography>
          <FontAwesomeIcon icon={faPrint} size="4x" />
        </div>
        {out}
      </NewWindow>
    );
  } else {
    return out;
  }
}