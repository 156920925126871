import React from "react";
import { css } from "@emotion/react";
import Typography from "../../../components/Typography";

const cssDescriptorParent = css`
  padding: 10px;
  margin: 10px;
  display: grid;
  grid-template-columns: 125px 1fr;
  grid-template-rows: 125px;
  gap: 0px 0px;
  grid-template-areas: ". .";
  border-top: 2px solid white;
`;

const cssImage = css`
  background-size: cover;
  background-position: center;
  box-shadow: inset -15px 0 20px -5px #111111;
  border-radius: 10px;
`;
const cssTextArea = css`
  padding: 10px;
`;

interface DescriptorProps {
  name: string;
  description: string;
  image: string;
  onClick?: () => void;
}

const Descriptor = (p: DescriptorProps) => {
  const cssImageDynamic = p.image
    ? css`
        background-image: url("${p.image}");
      `
    : null;
  return (
    <div css={cssDescriptorParent} onClick={p.onClick}>
      <div css={[cssImage, cssImageDynamic]} />
      <div css={cssTextArea}>
        <Typography role="subheading">{p.name}</Typography>
        <Typography role="label">{p.description}</Typography>
      </div>
    </div>
  );
};

interface LexiconListProps {
  name: string;
  description: string;
  descriptors: {
    id: string;
    name: string;
    description: string;
    image: string;
  }[];
}

const LexiconList = (p: LexiconListProps) => {
  return (
    <div>
      <Typography center role="heading">
        {p.name}
      </Typography>
      <Typography center role="paragraph">
        {p.description}
      </Typography>
      <div
        css={css`
          overflow: auto;
        `}
      >
        {p.descriptors.map((d, idx) => {
          return (
            <Descriptor
              key={d.id}
              name={d.name}
              description={d.description}
              image={d.image}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LexiconList;
