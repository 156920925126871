import {
  faCheckSquare,
  faDotCircle,
  faTimes,
  faTint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useIsMount } from "../../../../util/useIsMount";
import { css } from "@emotion/react";
import ColorPicker from "./ColorPicker";
import { sanitizeColor } from "../../../../util/SanitizeColor";

const styles = {
  colorPickerOuter: css`
    position: relative;
  `,
  colorPickerInner: css`
    left: 10px;
    position: absolute;
    z-index: 2;
  `,
};

interface DecorationProps {
  type: string;
  index: number;
}

function Decoration(p: DecorationProps) {
  if (p.type === "select_radio") {
    return (
      <Box
        width="42px"
        height="42px"
        lineHeight="38px"
        textAlign="center"
        fontWeight="bold"
      >
        <FontAwesomeIcon icon={faDotCircle} />
      </Box>
    );
  } else if (p.type === "select_checkbox") {
    return (
      <Box
        width="42px"
        height="42px"
        lineHeight="38px"
        textAlign="center"
        fontWeight="bold"
      >
        <FontAwesomeIcon icon={faCheckSquare} />
      </Box>
    );
  } else if (p.type === "select_dropdown") {
    return (
      <Box
        width="42px"
        height="42px"
        lineHeight="38px"
        textAlign="center"
        fontWeight="bold"
      >
        {p.index + 1}
      </Box>
    );
  } else if (p.type === "select_color") {
    return (
      <Box
        width="42px"
        height="42px"
        lineHeight="38px"
        textAlign="center"
        fontWeight="bold"
      >
        <FontAwesomeIcon icon={faTint} />
      </Box>
    );
  } else if(p.type === "select_evaluation"){
    return (
      <Box
        width="42px"
        height="42px"
        lineHeight="38px"
        textAlign="center"
        fontWeight="bold"
      >
        <FontAwesomeIcon icon={faDotCircle} />
      </Box>
    );
  }else {
    return <div>?</div>;
  }
}

export interface QuestionOption {
  id: string;
  text: string;
}

export interface OptionsProps {
  options: QuestionOption[];
  type: string;
  onOptionCreated: (option: QuestionOption) => void;
  onOptionUpdated: (option: QuestionOption) => void;
  onOptionDeleted: (optionId: string) => void;
}

const Options = (p: OptionsProps) => {
  const firstMount = useIsMount();
  const [options, setOptions] = useState([...p.options]);
  const [colorPickerIndex, setColorPickerIndex] = useState<number | null>(null);

  useEffect(() => {
    if (p.type !== "select_color") {
      setColorPickerIndex(null);
    }
  }, [p.type]);

  useEffect(() => {
    if (firstMount && p.options.length === 0) {
      handleAddOption();
    }
  // eslint-disable-next-line
  }, [firstMount]);

  const handleOptionDeleted = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
    p.onOptionDeleted(options[index].id);
  };

  const handleOptionUpdated = (index: number, newValue: string) => {
    const newOptions = [...options];
    newOptions[index] = { ...newOptions[index], text: newValue };
    setOptions(newOptions);
    p.onOptionUpdated(newOptions[index]);
  };

  const getNextOptionName = (index: number): string => {
    let name = `Option ${index}`;
    if (options.find((o) => o.text === name)) {
      return getNextOptionName(index + 1);
    }
    return name;
  };

  const handleAddOption = () => {
    const newOption = {
      id: uuidv4(),
      text: getNextOptionName(options.length + 1),
    };
    setOptions([...options, newOption]);
    p.onOptionCreated(newOption);
  };

  return (
    <React.Fragment>
      <Typography variant="subtitle1">Selection Options</Typography>
      <Box display="flex" flexDirection="column">
        {options.map((o, index) => {
          return (
            <Box key={o.id} display="flex" flexDirection="row">
              <Decoration type={p.type} index={index} />
              <TextField
                InputProps={
                  p.type === "select_color"
                    ? {
                        readOnly: true,
                      }
                    : {}
                }
                value={o.text}
                onChange={(e) => handleOptionUpdated(index, e.target.value)}
                fullWidth={p.type !== "select_color"}
                onClick={(e) => setColorPickerIndex(index)}
              />
              {p.type === "select_color" ? (
                <React.Fragment>
                  <div
                    css={css`
                      height: 30px;
                      width: 30px;
                      border: 1px solid black;
                      background-color: ${sanitizeColor(o.text)};
                    `}
                  ></div>
                  {colorPickerIndex === index ? (
                    <React.Fragment>
                      <div css={styles.colorPickerOuter}>
                        <div css={styles.colorPickerInner}>
                          <ColorPicker
                            color={o.text}
                            onColorChange={(newColor) =>
                              handleOptionUpdated(index, newColor)
                            }
                            onClose={() => setColorPickerIndex(null)}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                  <Box flexGrow={1}></Box>
                </React.Fragment>
              ) : null}
              <IconButton
                onClick={(_e) => handleOptionDeleted(index)}
                aria-label="delete"
              >
                <FontAwesomeIcon icon={faTimes} fixedWidth />
              </IconButton>
            </Box>
          );
        })}
        <Button onClick={handleAddOption}>Add Option</Button>
      </Box>
    </React.Fragment>
  );
}

const memoized = React.memo(Options);

export default memoized;