import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";
import QInputProps from "./QInputProps";

export default function QSelectRadio(p: QInputProps) {
  if (p.options == null || p.options.length === 0) {
    return <Typography>No options available.</Typography>;
  }
  return (
    <div>
      <RadioGroup
        value={p.value || null}
        onChange={(e, v) => p.onValueChange(v)}
      >
        {p.options.map((option) => (
          <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
        ))}
      </RadioGroup>

    </div>
  );
}
