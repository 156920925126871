import { gql } from "@apollo/client";

export const GetLexiconById = gql`
query GetLexiconById($id: uuid!) {
  lexicon_by_pk(id: $id) {
    id
    name
    image
    description
    qr_codes {
      id
      public_url
    }
    descriptors(order_by: {name: asc}) {
      id
      name
    }
  }
}
`;

export interface GetLexiconByIdVariables {
  id: string;
}

export interface GetLexiconByIdResponse {
  lexicon_by_pk: {
    id: string;
    name: string;
    image: string;
    description: string;
    qr_codes: {
      id: string;
      public_url?: string;
    }[];
    descriptors: {
      id: string;
      name: string;
    }[]
  }
}

export const ListDescriptors = gql`
query ListDescriptors {
  descriptor(order_by: {name: asc}) {
    id
    name
    image
    description
  }
}
`;

export interface ListDescriptorsResponse {
  descriptor: {
    id: string;
    name: string;
    image: string;
    description: string;
  }[];
}

export const ListLexiconNames = gql`
query ListLexiconNames {
  lexicon {
    id
    name
  }
}
`;

export interface ListLexiconNamesResponse {
  lexicon: {
    id: string;
    name: string;
  }[];
}

export const ListLexicons = gql`
query ListLexicons {
  lexicon {
    id
    name
    image
    description
    qr_codes {
      id
      public_url
    }
    lexicon_descriptors_aggregate {
      aggregate {
        count
      }
    }
  }
}
`;

export interface ListLexiconsResponse {
  lexicon: {
    id: string;
    name: string;
    image: string;
    description: string;
    qr_codes: {
      id: string;
      public_url?: string;
    }[];
    lexicon_descriptors_aggregate: {
      aggregate: {
        count: number;
      }
    }
  }[];
}

export const UpdateLexicon = gql`
mutation UpdateLexicon($id: uuid!, $name: String!, $image: String!, $description: String!, $descriptors: [lexicon_descriptor_insert_input!]!) {
  delete_lexicon_descriptor(where: {lexicon_id: {_eq: $id}}) {
    affected_rows
  }
  update_lexicon_by_pk(pk_columns: {id: $id}, _set: {name: $name, image: $image, description: $description}) {
    id
    name
    image
    description
  }
  insert_lexicon_descriptor(objects: $descriptors, on_conflict: {constraint: lexicon_descriptor_pkey, update_columns: descriptor_id}) {
    affected_rows
  }
}
`;

export interface UpdateLexiconVariables {
  id: string;
  name: string;
  description: string;
  image: string;
  descriptor_ids: string[];
  descriptors: {descriptor_id: string; lexicon_id: string}[]
}

export const buildUpdateLexiconVariables = (lexiconId: string, name: string, description: string, image: string, descriptorIds: string[]): UpdateLexiconVariables => {
  return {
    id: lexiconId,
    name: name,
    description: description,
    image: image,
    descriptor_ids: descriptorIds,
    descriptors: descriptorIds.map(descId => ({descriptor_id: descId, lexicon_id: lexiconId}))
  }
}

export const PrintLexiconLabels = gql`
mutation PrintLexiconLabels($lexicon_id: uuid!) {
  print_lexicon_labels(lexicon_id: $lexicon_id) {
    url
  }
}
`

export interface PrintLexiconLabelsVariables {
  lexicon_id: string;
}

export interface PrintLexiconLabelsData {
  print_lexicon_labels: {
    url: string;
  }
}

export const DeleteLexiconMutation = gql`
mutation DeleteLexicon($lexicon_id: uuid!) {
  delete_lexicon_by_pk(id: $lexicon_id) {
    id
  }
}
`

export interface DeleteLexiconVariables {
  lexicon_id: string;
}

export interface DeleteLexiconData {
  delete_lexicon_by_pk: {
    id: string;
  }
}

export const GetLexiconQrCodes = gql`
query GetLexiconQrCodes($lexicon_id: uuid!) {
  lexicon_by_pk(id: $lexicon_id) {
    id
    name
    qr_codes_aggregate {
      aggregate {
        max {
          code
        }
      }
    }
    descriptors {
      id
      name
      qr_codes_aggregate {
        aggregate {
          max {
            code
          }
        }
      }
    }
  }
}
`;

export interface GetLexiconQrCodesVariables {
  lexicon_id: string;
}

export interface GetLexiconQrCodesData {
  lexicon_by_pk: {
    id: string;
    name: string;
    qr_codes_aggregate: {
      aggregate: {
        max: {
          code: string;
        };
      };
    };
    descriptors: {
      id: string;
      name: string;
      qr_codes_aggregate: {
        aggregate: {
          max: {
            code: string;
          };
        };
      };
    }[];
  };
}
