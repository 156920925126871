import React from "react";
import { css } from "@emotion/react";
import { Typography } from "@material-ui/core";
import {
  faBook,
  faFileCsv,
  faFolder,
  faPoll,
  faSignOutAlt,
  faUser,
  faVial,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Logo from "../../components/Logo";

const styles = {
  sideBarLink: css`
    display: flex;
    padding: 20px;
    color: inherit;
    text-decoration: inherit;
  `,
  logoBox: css`
    display: flex;
    justify-content: center;
    padding: 10px 0px;
  `
};

const sideBarLinks = [
  { route: "/admin/descriptors", name: "Descriptors", icon: faVial },
  { route: "/admin/lexicons", name: "Lexicons", icon: faBook },
  { route: "/admin/sessions", name: "Sessions", icon: faFolder },
  { route: "/admin/surveys", name: "Surveys", icon: faPoll },
  { route: "/admin/users", name: "Users", icon: faUser },
  { route: "/admin/export", name: "Data Export", icon: faFileCsv },
  { route: "/logout", name: "Logout", icon: faSignOutAlt },
];

export default function SideBar() {
  return (
    <div>
      <div css={styles.logoBox}>
      <Logo height={30} />
      </div>
      {sideBarLinks.map((link) => (
        <Link key={link.name} css={styles.sideBarLink} to={link.route}>
          <FontAwesomeIcon
            fixedWidth
            icon={link.icon}
            css={css`
              padding: 10px;
              font-size: 20px;
            `}
          />
          <Typography
            css={css`
              padding: 10px;
            `}
          >
            {link.name}
          </Typography>
        </Link>
      ))}
    </div>
  );
}
