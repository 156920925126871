import React from "react";
import { css } from "@emotion/react";

const styles = {
  descriptorRatingGroup: css``,
  descriptorRatingGroupName: css`
    display: inline-block;
    width: 80px;
    height: 20px;
    margin: 2px;
    padding: 5px;
  `,
  descriptorRatingBox: css`
    display: inline-block;
    height: 20px;
    margin: 2px;
    padding: 5px;
    border: 1px solid black;
  `,
};

const ratingColors = [
  css`
    background-color: rgb(125, 125, 125);
  `,
  css`
    background-color: rgb(241, 247, 214);
  `,
  css`
    background-color: rgb(180, 212, 71);
  `,
  css`
    background-color: rgb(91, 128, 41);
  `,
];

interface DescriptorRatingGroupProps {
  groupName: string;
  score: number;
  descriptorNames: string[];
}

export default function DescriptorRatingGroup(p: DescriptorRatingGroupProps) {
  return (
    <div css={styles.descriptorRatingGroup}>
      <div css={styles.descriptorRatingGroupName}>{p.groupName}</div>
      {p.descriptorNames.map((name) => (
        <div css={[styles.descriptorRatingBox, ratingColors[p.score]]}>
          {name}
        </div>
      ))}
    </div>
  );
};
