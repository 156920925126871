import React from "react";
import { css } from "@emotion/react";
import { FormControlLabel, Switch } from "@material-ui/core";

const styles = {
  container: css`
  @media print {
    display: none;
  }`,
};

interface ShareToggleProps {
  evaluators: number;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, newState: boolean) => void;
}

export default function ShareToggle(p: ShareToggleProps) {
  return (
    <FormControlLabel
      control={
        <Switch checked={p.checked} onChange={p.onChange} name="share" />
      }
      label={`Share table with ${p.evaluators} evaluators`}
      css={styles.container}
    />
  );
}
