import React, { useState } from "react";
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import { css } from "@emotion/react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { DeleteDescriptorData, DeleteDescriptorMutation, DeleteDescriptorVariables } from "./queries";

const styles = {
  backdrop: css`
    z-index: 999,
    color: '#f00',
  `,
};

interface DeleteDescriptorProps {
  descriptorId: string;
}

export default function DeleteDescriptor(p: DeleteDescriptorProps) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [deleteDescriptor] = useMutation<DeleteDescriptorData, DeleteDescriptorVariables>(DeleteDescriptorMutation, {refetchQueries: ["GetAdminPanelDescriptors"]})

  const redirectToList = () => {
    history.replace(`/admin/descriptors`)
  }
  const handleDeleteClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirmDelete = () => {
    deleteDescriptor({variables: {descriptor_id: p.descriptorId}}).then(redirectToList).catch(e => {
      setError(e.toString())
    });
  }

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleDeleteClick}
        disabled={open}
      >
        Delete
      </Button>
      <Backdrop
        css={styles.backdrop}
        style={{ zIndex: 999 }}
        open={open}
      >
        <Card
          css={css`
            max-width: 300px;
          `}
        >
          <CardContent>
            <Typography variant="h6">Delete Descriptor</Typography>
            <Typography variant="body2">
              This descriptor along with its QR code and any feedback
              will be permanently deleted. This action cannot be reversed.
            </Typography>
            {error ? <Typography variant="body2">
              Error deleting: {error}
            </Typography> : null}
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button variant="outlined" color="secondary" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </CardActions>
        </Card>
      </Backdrop>
    </React.Fragment>
  );
}
