import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { SessionProvider, useSession } from "./SessionContext";
import Login from "./pages/Login";
import AdminPage from "./admin";
import decodeJwt from "jwt-decode";
import LogOut from "./pages/Login/LogOut";
import isMobile from "is-mobile";
import LexiconPage from "./pages/mobile/pages/lexicon/LexiconPage";
import LexiconDescriptorPage from "./pages/mobile/pages/lexicon/LexiconDescriptorPage";
import ProjectLexiconPage from "./pages/mobile/pages/project/ProjectLexiconPage";
import ProjectLexiconDescriptorPage from "./pages/mobile/pages/project/ProjectLexiconDescriptorPage";
import SamplesPage from "./pages/mobile/pages/project/SamplesPage";
import SampleDetailsPage from "./pages/mobile/pages/project/SampleDetailsPage";
import ProjectScanPage from "./pages/mobile/pages/project/ProjectScanPage";
import DescriptorPage from "./pages/mobile/pages/descriptor/DescriptorPage";
import ExternalScan from "./pages/mobile/pages/externalScan";
import { ProjectReportPage } from "./pages/mobile/pages/project/ProjectReportPage";
import Welcome from "./mobile/pages/welcome/Welcome";
import SurveyPage from "./survey/SurveyPage";

const httpLink = createHttpLink({
  uri: "https://gvisa-hasura.onrender.com/v1/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("fu2_jwt");
  if (!token) {
    return { headers };
  }
  const role =
    (decodeJwt(token) as any)["https://hasura.io/jwt/claims"][
      "x-hasura-default-role"
    ] || "user";
  return {
    headers: {
      ...headers,
      Authorization: "Bearer " + token,
      "x-hasura-role": role,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function MainView() {
  const { jwt, sessionId, isAdmin } = useSession();
  const useAdminView = !isMobile() && isAdmin;
  if (!jwt) {
    return (
      <Switch>
        <Route
          path="/survey/:surveyId"
          exact={false}
          strict={false}
          render={(p) => <SurveyPage surveyId={p?.match?.params["surveyId"]} />}
        />
        <Route
          path="/login/:code?"
          exact={false}
          strict={false}
          render={(p) => <Login then={p?.match?.params["code"]} />}
        />
        {/* <Route
          path="/q/:code"
          exact={false}
          strict={false}
          render={(p) => <Redirect to={`/login/${p?.match.params["code"]}`} />}
        /> */}
        <Route
          path="/q/:code"
          exact={false}
          strict={false}
          render={(p) => <ExternalScan code={p.match.params["code"]} />}
        />
        <Route path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
    );
  }
  return (
    <Switch>
      <Route
        path="/survey/:surveyId"
        exact={false}
        strict={false}
        render={(p) => <SurveyPage surveyId={p?.match?.params["surveyId"]} />}
      />
      <Route path="/logout">
        <LogOut />
      </Route>
      <Route path="/admin">
        <AdminPage />
      </Route>
      <Route path="/app/welcome">
        <Welcome />
      </Route>
      <Route
        path="/q/:code"
        exact={false}
        strict={false}
        render={(p) => <ExternalScan code={p.match.params["code"]} />}
      />
      <Route
        path="/app/descriptor/:descriptorId"
        exact={false}
        strict={false}
        render={(p) => (
          <DescriptorPage descriptorId={p.match.params["descriptorId"]} />
        )}
      />
      <Route path="/app/project" exact={false} strict={false}>
        <Switch>
          <Route
            path="/app/project/:projectId/report"
            exact={false}
            strict={false}
            render={(p) => (
              <ProjectReportPage projectId={p.match.params["projectId"]} />
            )}
          />
          <Route
            path="/app/project/:projectId/scan"
            exact={false}
            strict={false}
            render={(p) => (
              <ProjectScanPage projectId={p.match.params["projectId"]} />
            )}
          />
          <Route
            path="/app/project/:projectId/samples/:sampleId"
            exact={false}
            strict={false}
            render={(p) => (
              <SampleDetailsPage
                projectId={p.match.params["projectId"]}
                sampleId={p.match.params["sampleId"]}
              />
            )}
          />
          <Route
            path="/app/project/:projectId/samples"
            exact={false}
            strict={false}
            render={(p) => (
              <SamplesPage projectId={p.match.params["projectId"]} />
            )}
          />
          <Route
            path="/app/project/:projectId/lexicon/:descriptorId"
            exact={false}
            strict={false}
            render={(p) => (
              <ProjectLexiconDescriptorPage
                projectId={p.match.params["projectId"]}
                descriptorId={p.match.params["descriptorId"]}
              />
            )}
          />
          <Route
            path="/app/project/:projectId/lexicon"
            exact={false}
            strict={false}
            render={(p) => (
              <ProjectLexiconPage projectId={p.match.params["projectId"]} />
            )}
          />
          <Route
            path="/app/project/:projectId"
            exact={false}
            strict={false}
            render={(p) => (
              <Redirect
                to={`/app/project/${p.match.params["projectId"]}/samples`}
              />
            )}
          />
        </Switch>
      </Route>
      <Route path="/app/lexicon" exact={false} strict={false}>
        <Switch>
          <Route
            path="/app/lexicon/:lexiconId/:descriptorId"
            exact={false}
            strict={false}
            render={(p) => (
              <LexiconDescriptorPage
                lexiconId={p.match.params["lexiconId"]}
                descriptorId={p.match.params["descriptorId"]}
              />
            )}
          />
          <Route
            path="/app/lexicon/:lexiconId"
            exact={false}
            strict={false}
            render={(p) => (
              <LexiconPage lexiconId={p.match.params["lexiconId"]} />
            )}
          />
        </Switch>
      </Route>
      {/* This redirect is invoked after a not-logged-in user scans a code, then logs in.
          We redirect the user back to the scan handler page so that they can be redirected to the correct page. */}
      <Route
        path="/login/:code"
        exact={false}
        strict={false}
        render={(p) => <div>logged in and waiting for redirect<Redirect to={`/q/${p.match.params['code']}`}/></div>}
      />
      <Route path="/">
        {useAdminView ? (
          <Redirect to={`/admin`} />
        ) : sessionId ? (
          <Redirect to={`/app/project/${sessionId}`} />
        ) : (
          <Redirect to="/app/welcome" />
        )}
      </Route>
    </Switch>
  );
}

function App() {
  return (
    <SessionProvider>
      <ApolloProvider client={client}>
        <Router>
          <MainView />
        </Router>
      </ApolloProvider>
    </SessionProvider>
  );
}

export default App;
