import { useLazyQuery } from '@apollo/client';
import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Label } from '../labels/label';
import { GetLexiconQrCodes, GetLexiconQrCodesData, GetLexiconQrCodesVariables } from './queries';

interface PrintLabelsButtonProps {
  lexiconId: string;
}

export function PrintLabelsButton(p: PrintLabelsButtonProps) {
  const history = useHistory();
  const { load, data, loading, error } = useLexiconQrCodes(p.lexiconId);

  const handleOnClick = () => {
    load();
  };
  useEffect(() => {
    if (data) {
      history.push("/admin/labels", data);
    }
  }, [data, history]);

  return (
    <Button onClick={handleOnClick} disabled={loading} variant="contained">
      {loading ? "Loading" : error ? error.name : "Print Label Sheet"}
    </Button>
  );
}

export function useLexiconQrCodes(lexiconId: string) {
  function getLabelsFromResponse(data: GetLexiconQrCodesData): Label[] {
    let out = [] as Label[];
    out.push({
      type: "lexicon",
      caption: data.lexicon_by_pk.name,
      qrCode: data.lexicon_by_pk.qr_codes_aggregate.aggregate.max.code,
    });
    data.lexicon_by_pk.descriptors.forEach((descriptor) => {
      out.push({
        type: "descriptor",
        caption: descriptor.name,
        qrCode: descriptor.qr_codes_aggregate.aggregate.max.code,
      });
    });

    return out;
  }
  const [load, { data, loading, error }] = useLazyQuery<
    GetLexiconQrCodesData,
    GetLexiconQrCodesVariables
  >(GetLexiconQrCodes, { variables: { lexicon_id: lexiconId } });

  return {
    load,
    data: data ? getLabelsFromResponse(data) : undefined,
    loading,
    error,
  };
}
