import React from "react";
import { css } from "@emotion/react";
import useObjectCounts from "./queries/ObjectCounts";
import ObjectSummaryItem from "./ObjectSummaryItem";
import { Typography } from "@material-ui/core";

const styles = {
  container: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em 1em;
  `,
  header: css`
    grid-column: 1 / 3
  `
};

interface ObjectSummaryProps {}

export default function ObjectSummary(p: ObjectSummaryProps) {
  const { data } = useObjectCounts();
  return (
    <div css={styles.container}>
      <Typography css={styles.header} variant="h5">Data Summary</Typography>
      <div>
        <ObjectSummaryItem
          label="Sessions"
          value={data?.project_aggregate?.aggregate?.count}
        />
        <ObjectSummaryItem
          label="Lexicons"
          value={data?.lexicon_aggregate?.aggregate?.count}
        />
        <ObjectSummaryItem
          label="Samples"
          value={data?.sample_aggregate?.aggregate?.count}
        />
        <ObjectSummaryItem
          label="Descriptors"
          value={data?.descriptor_aggregate?.aggregate?.count}
        />
      </div>
      <div>
        <ObjectSummaryItem
          label="Users"
          value={data?.app_user_aggregate?.aggregate?.count}
        />
        <ObjectSummaryItem
          label="Sample Evaluations"
          value={data?.evaluation_aggregate?.aggregate?.count}
        />
        <ObjectSummaryItem
          label="Descriptor Evaluations"
          value={data?.descriptor_rating_aggregate?.aggregate?.count}
        />
      </div>
    </div>
  );
}
