import { useLazyQuery} from "@apollo/client";
import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Label } from "../labels/label";
import {
  GetProjectQrCodes,
  GetProjectQrCodesData,
  GetProjectQrCodesVariables,
} from "./queries";

interface PrintLabelsButtonProps {
  projectId: string;
}

export function PrintLabelsButton(p: PrintLabelsButtonProps) {
  const history = useHistory();
  const { load, data, loading, error } = useProjectQrCodes(p.projectId);

  const handleOnClick = () => {
    load();
  };
  useEffect(() => {
    if (data) {
      history.push("/admin/labels", data);
    }
  }, [data, history]);

  return (
    <Button onClick={handleOnClick} disabled={loading} variant="contained">
      {loading ? "Loading" : error ? error.name : "Print Label Sheet"}
    </Button>
  );
}

export function useProjectQrCodes(projectId: string) {
  function getLabelsFromResponse(data: GetProjectQrCodesData): Label[] {
    let out = [] as Label[];
    out.push({
      type: "project",
      caption: data.project_by_pk.name,
      qrCode: data.project_by_pk.qr_codes_aggregate.aggregate.max.code,
    });
    data.project_by_pk.samples.forEach((sample) => {
      out.push({
        type: "sample",
        caption: sample.name,
        qrCode: sample.qr_codes_aggregate.aggregate.max.code,
      });
    });

    return out;
  }
  const [load, { data, loading, error }] = useLazyQuery<
    GetProjectQrCodesData,
    GetProjectQrCodesVariables
  >(GetProjectQrCodes, { variables: { project_id: projectId } });

  return {
    load,
    data: data ? getLabelsFromResponse(data) : undefined,
    loading,
    error,
  };
}
