import { css } from "@emotion/react";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { Box, Button, CardContent, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { QuestionCardProps } from "../admin/pages/surveys/components/QuestionCard";
import SurveyCard from "../components/SurveyCard";
import {
  getSelectionCount,
  isMultiSelectType,
} from "./components/inputs/util/selectionUtilities";
import QuestionCard, {
  QuestionCardConstantProps,
} from "./components/QuestionCard";




function getErrorMessage(
  p: QuestionCardConstantProps,
  value: string | null
): string | null {
  if (p.required && value == null) {
    return "This question is required.";
  }
  if (
    isMultiSelectType(p.type) &&
    p.validationMode != null &&
    p.validationCount != null &&
    value != null
  ) {
    const selectionCount = getSelectionCount(value);
    switch (p.validationMode) {
      case "at_least":
        if (selectionCount < p.validationCount) {
          return (
            p.validationErrorMessage ||
            `You must make at least ${p.validationCount} selections.`
          );
        }
        break;
      case "at_most":
        if (selectionCount > p.validationCount) {
          return (
            p.validationErrorMessage ||
            `You must make at most ${p.validationCount} selections.`
          );
        }
        break;
      case "exactly":
        if (selectionCount !== p.validationCount) {
          return (
            p.validationErrorMessage ||
            `You must make exactly ${p.validationCount} selections.`
          );
        }
        break;
    }
  }

  return null;
}

export interface Answers {
  [questionId: string]: string | null;
}

export interface SurveyFormDataProps {
  name: string;
  description: string;
  questions: QuestionCardConstantProps[];
}

export interface SurveyFormProps extends SurveyFormDataProps {
  onSubmit: (answers: Answers) => void;
  onError: () => void;
}

export default function SurveyForm(p: SurveyFormProps) {
  const [answers, setAnswers] = useState<Answers>({});
  const [wasSubmittedWithErrors, setWasSubmittedWithErrors] =
    useState<boolean>(false);
  const [correct,setCorret] = useState<string[]>([]);
  const [total,setTotal] = useState<number>(0)
  const [len,setLength] = useState<number>(0)
  const hasAnyRequiredFields = p.questions.find((q) => q.required) != null;
  const handleValueChanged = (questionId: string, value: string | null) => {
    const newAnswers = { ...answers };
    newAnswers[questionId] = value;
    setAnswers(newAnswers);
  };
  const handleSubmit = () => {
    // Loop through each question, check if it has any errors.
    for (let q of p.questions) {
      if (getErrorMessage(q, answers[q.id]) != null) {
        setWasSubmittedWithErrors(true);
        p.onError();
        return;
      }
    }

    p.onSubmit(answers);
  };

  useEffect(()=>{
    let tempTotal = 0
    let tempLen = 0
    p.questions.map((q,index)=>{
      if(q.answer !== "" && q.answer !== undefined && (q.parent === "" || q.parent === null)){
        tempTotal++
      }
      if(q.parent === "" || q.parent === null){
        tempLen++
      }
    
    })
    setLength(tempLen)
    console.log(tempLen)
    setTotal(tempTotal)
  },[])

  function eval_questions(q: QuestionCardConstantProps, value: String){
    if(q.type === "select_radio" && q.answer !== null){
      if(answers[q.id] !== undefined && q.answer !== ""){
        if(value === q.answer){
          if(!correct.includes(String(q.id))){
            if(q.parent === "" || q.parent === null){
              correct.push(q.id)
            }
          }
        }
      }
    }
  }
  function eval_quesiton(q: QuestionCardConstantProps, value: String):string{
    if(q.type === "select_radio" && q.answer !== null){
      if(answers[q.id] !== undefined && q.answer !== ""){
        if(value === q.answer){
          return ""
        }else{
          return "Try Again"
        }
      }
    }
    return ""
  }
  
  

  function find_parent(parent: String, answer: String):boolean{
    let found = 0
    p.questions.map((q,index)=>{
      if(q.id === parent){
        if(answers[q.id] === answer){
          found = 1

        }
      }
    })
    if(found === 0){
      return false
    }else{
      return true
    }
  }
  

  return (
    <div
      css={css`
        width: 100%;
        max-width: 500px;
      `}
    >
      <SurveyCard topStripe>
        <CardContent>
          <Typography variant="h4">{p.name}</Typography>
          <Typography variant="body1">{p.description}</Typography>
          {hasAnyRequiredFields ? (
            <Typography
              css={css`
                color: #ff3333;
              `}
            >
              * Required
            </Typography>
          ) : null}
        </CardContent>
      </SurveyCard>
      {p.questions.map((questionProps) => {
        const value = answers[questionProps.id];
        const errorMessage = wasSubmittedWithErrors
          ? getErrorMessage(questionProps, value)
          : null; // Only show error nags if user has tried to submit
        eval_questions(questionProps,String(value))
        let status = eval_quesiton(questionProps, String(value))
        if(questionProps.parent !== ""){
          console.log("hit 1: checking answer")
          let evaluation = find_parent(questionProps.parent, questionProps.logic_value)
          if(evaluation){
            return(
              <div>
                  <Box key={questionProps.id} marginTop={1} width="100%">
                    <QuestionCard
                      value={value} 
                      errorMessage={errorMessage}
                      status={status}
                      onValueChanged={(newValue) =>
                        handleValueChanged(questionProps.id, newValue)
                      }
                      {...questionProps}
                    />
                  </Box>
              </div>
            )
          }
        }else{
          return (
            <Box key={questionProps.id} marginTop={1} width="100%">
              <QuestionCard
                value={value}
                status={status} 
                errorMessage={errorMessage}
                onValueChanged={(newValue) =>
                  handleValueChanged(questionProps.id, newValue)
                }
                {...questionProps}
              />
            </Box>
          );
        }
      })
      }
      {
      len <= Object.keys(answers).length ? (
          <Box>
            <h3>Score: {correct.length}/{total}</h3>
          </Box>
        
      ): null
      }
      <Box marginTop={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit Response
        </Button>
      </Box>
    </div>
  );
}
