import React, { useState } from "react";
import { css } from "@emotion/react";
import parse from "parse-color";

const cssParent = css`
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
padding: 5px;
box-sizing: border-box;
`

const cssParentEditing = css`
width: 125px;
height: 75px;
`

const cssParentNotEditing = css`
width: 125px;
height: 40px;
`

const cssColorInput = css`
text-align: center;
margin: 5px;
`

interface DescriptorColorProps {
  editing: boolean;
  color?: string;
  colorName?: string;
  onColorChange?: (color: string) => void;
  onColorNameChange?: (colorName: string) => void;
}

function getColorLuminance(color: string): number {
  const [r,g,b] = parse(color).rgb;
  return Math.sqrt( 0.299*r*r + 0.587*g*g + 0.114*b*b )
}

function getTextColor(backgroundColor: string): string {
  const luminance = getColorLuminance(backgroundColor);
  return luminance >= 128 ? "#000" : "#fff";
}

function isValidColor(color: string | undefined): boolean {
  if (!color) {
    return false;
  }
  try {
    return parse(color).rgb.length > 0;
  } catch (e) {
    return false;
  }
}

function normalizeColor(color: string): string {
  if (color.includes("rgb(")) {
    return parse(color).hex;    
  }
  return color;
}

const DescriptorColor = (p: DescriptorColorProps) => {
  const [color, setColor] = useState(p.color);
  const [colorName, setColorName] = useState(p.colorName);
  const backgroundColor = isValidColor(color) ? color! : '#000000'
  const textColor = getTextColor(backgroundColor);
  const cssBackgroundColor = css`background-color: ${backgroundColor}; border: 1px solid ${textColor}`;
  const cssTextColor = css`color: ${textColor}`;
  const cssInputCustom = css`border: 1px solid ${textColor} !important; color: black important!; background-color: white importan!;`;

  const handleColorChange = (newColor: string) => {
    if (isValidColor(newColor)) {
      newColor = normalizeColor(newColor);
    }
    setColor(newColor)
    if (p.onColorChange && isValidColor(newColor)) {
      p.onColorChange(newColor)
    }
  }
  const handleColorNameChange = (newColorName: string) => {
    setColorName(newColorName);
    if (p.onColorNameChange) {
      p.onColorNameChange(newColorName);
    }
  }

  if (p.editing) {
    return (<div css={[cssParent, cssParentEditing, cssBackgroundColor]}>
      <input css={[cssColorInput, cssInputCustom]} value={colorName} onChange={e => handleColorNameChange(e.target.value)}/>
      <input css={[cssColorInput, cssInputCustom]} value={color} onChange={e => handleColorChange(e.target.value)}/>
      </div>);
  } else {
    return (<div css={[cssParent, cssParentNotEditing, cssBackgroundColor]}>
      <span css={cssTextColor}>{p.colorName || 'Name not set'}</span>
      </div>);
  }
}

export default DescriptorColor;

