import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFont,
  faAlignLeft,
  faDotCircle,
  faCheckSquare,
  faCaretSquareDown,
  faStar,
  faHeart,
  faSlidersH,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
  Box,
} from "@material-ui/core";
import React from "react";

interface TypeMenuItemProps {
  icon: IconProp;
  label: string;
}

function TypeMenuItem(p: TypeMenuItemProps) {
  return (
    <React.Fragment>
      <Box paddingRight={1} display="inline">
        <FontAwesomeIcon icon={p.icon} fixedWidth />
      </Box>
      {p.label}
    </React.Fragment>
  );
}

export interface QuestionTypeSelectProps {
  value: string;
  onChange: (newValue: string) => void;
}

export default function QuestionTypeSelect(p: QuestionTypeSelectProps) {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
      <Select
        fullWidth
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={p.value}
        onChange={(e) => p.onChange(e.target.value as string)}
        label="Type"
      >
        <ListSubheader>Text</ListSubheader>
        <MenuItem value={"text_short"}>
          <TypeMenuItem label="Short Answer" icon={faFont} />
        </MenuItem>
        <MenuItem value={"text_paragraph"}>
          <TypeMenuItem label="Paragraph" icon={faAlignLeft} />
        </MenuItem>
        <ListSubheader>Selection</ListSubheader>
        <MenuItem value={"select_dropdown"}>
          <TypeMenuItem label="Dropdown" icon={faCaretSquareDown} />
        </MenuItem>
        <MenuItem value={"select_radio"}>
          <TypeMenuItem label="Radio Buttons" icon={faDotCircle} />
        </MenuItem>
        <MenuItem value={"select_checkbox"}>
          <TypeMenuItem label="Checkboxes" icon={faCheckSquare} />
        </MenuItem>
        <MenuItem value={"select_color"}>
          <TypeMenuItem label="Colors" icon={faPalette} />
        </MenuItem>
        <ListSubheader>Ratings</ListSubheader>
        <MenuItem value={"rating_star"}>
          <TypeMenuItem label="Star Rating" icon={faStar} />
        </MenuItem>
        <MenuItem value={"rating_heart"}>
          <TypeMenuItem label="Heart Rating" icon={faHeart} />
        </MenuItem>
        <MenuItem value={"rating_slider"}>
          <TypeMenuItem label="Slider Rating" icon={faSlidersH} />
        </MenuItem>        
      </Select>
    </FormControl>
  );
}
