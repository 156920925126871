import React from "react";
import { Tabs as MUITabs, Tab as MUITab } from "@material-ui/core";

interface TabsProps {
  value?: TabPage;
  onChange?: (event: React.ChangeEvent<{}>, value: TabPage) => void;
}

export type TabPage = "overview" | "report";

export default function Tabs(p: TabsProps) {
  return (
    <MUITabs
      value={p.value}
      indicatorColor="primary"
      textColor="primary"
      onChange={p.onChange}
      aria-label="disabled tabs example"
    >
      <MUITab label="Overview" value={"overview"} />
      <MUITab label="Report" value={"report"} />
    </MUITabs>
  );
}
