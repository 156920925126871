import React from "react";
import { css } from "@emotion/react";
import { gql, useQuery } from "@apollo/client";
import Typography from "../../components/Typography";
import { useHistory } from "react-router-dom";

const cssDescriptorParent = css`
  padding: 10px;
  margin: 10px;
  display: grid;
  grid-template-columns: 125px 1fr;
  grid-template-rows: 125px;
  gap: 0px 0px;
  grid-template-areas: ". .";
  border-top: 2px solid white;
`;

const cssImage = css`
  background-size: cover;
  background-position: center;
  box-shadow: inset -15px 0 20px -5px #111111;
  border-radius: 10px;
`;
const cssTextArea = css`
  padding: 10px;
`;

interface DescriptorProps {
  name: string;
  description: string;
  image: string;
  onClick?: () => void;
}

export const Descriptor = (p: DescriptorProps) => {
  const cssImageDynamic = p.image
    ? css`
        background-image: url("${p.image}");
      `
    : null;
  return (
    <div css={cssDescriptorParent} onClick={p.onClick}>
      <div css={[cssImage, cssImageDynamic]} />
      <div css={cssTextArea}>
        <Typography role="subheading">{p.name}</Typography>
        <Typography role="label">{p.description}</Typography>
      </div>
    </div>
  );
};

const cssParent = css``;

interface LexiconProps {
  projectId: string;
}

interface QueryData {
  project_by_pk: {
    name: string;
    description: string;
    descriptors: [
      {
        id: string;
        image: string;
        name: string;
        family: string;
        description: string;
      }
    ];
  };
}

interface QueryVars {
  id: string;
}

const QUERY = gql`
  query GetDescriptors($id: uuid!) {
    project_by_pk(id: $id) {
      name
      description
      descriptors(order_by: {name: asc}) {
        id
        image
        name
        description
        family
      }
    }
  }
`;

export default function Lexicon (p: LexiconProps) {
  const { loading, error, data } = useQuery<QueryData, QueryVars>(QUERY, {
    variables: { id: p.projectId },
  });
  const history = useHistory();

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error...</div>;
  }
  if (!data) {
    return <div>No data...</div>;
  }

  const descriptors = data.project_by_pk.descriptors || [];

  return (
    <div css={cssParent}>
      <Typography center role="heading">
        {data.project_by_pk.name}
      </Typography>
      <Typography center role="paragraph">
        {data.project_by_pk.description}
      </Typography>
      {descriptors.map((desc) => {
        return (
          <Descriptor
            key={desc.id}
            image={desc.image}
            name={desc.name}
            description={desc.description}
            onClick={() =>
              history.push(`/project/${p.projectId}/descriptor/${desc.id}`)
            }
          />
        );
      })}
    </div>
  );
};
