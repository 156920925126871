import React from "react";
import { css } from "@emotion/react";
import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import _ from "lodash";

const styles = {
  container: css``,
  rotated: css`
    writing-mode: vertical-rl;
    // transform: rotate(-10deg);

    // /* Legacy vendor prefixes that you probably don't need... */

    // /* Safari */
    // -webkit-transform: rotate(-90deg);

    // /* Firefox */
    // -moz-transform: rotate(-90deg);

    // /* IE */
    // -ms-transform: rotate(-90deg);

    // /* Opera */
    // -o-transform: rotate(-90deg);

    // /* Internet Explorer */
    // filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  `,
  descriptorHeading: css`
    // width: 40px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
    line-height: 14px !important;
  `,
  narrowCell: css`
    padding: 6px 14px 6px 6px !important;
  `,
  descriptorEmphasis: css`
    font-weight: bold !important;
    color: green !important;
    font-size: 16px !important;
  `,
};

export interface DescriptorStats {
  id: string;
  name: string;
  descriptors: {
    id: string;
    name: string;
    tasters: number;
    percentTooWeak: number;
    percentTooStrong: number;
  }[];
}

interface DescriptorTableProps {
  samples: DescriptorStats[];
}

export default function DescriptorTable(p: DescriptorTableProps) {
  const allDescriptors = p.samples[0].descriptors.map((d) => d.name).sort();

  return (
    <Card>
      <CardContent>
        <Typography>Descriptor Strength</Typography>
      </CardContent>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Sample</TableCell>
              <TableCell>Metric</TableCell>
              {allDescriptors.map((descriptor) => (
                <TableCell
                  align="center"
                  key={descriptor}
                  css={styles.descriptorHeading}
                >
                  <span css={styles.rotated}>{descriptor}</span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {p.samples.map((sample) => (
              <React.Fragment key={sample.id}>
                <TableRow>
                  <TableCell rowSpan={2}>{sample.name}</TableCell>
                  <TableCell width={90}>% Too Weak</TableCell>
                  {_.sortBy(sample.descriptors, (d) => d.name).map((d) => (
                    <TableCell
                      align="center"
                      key={d.id}
                      css={[
                        styles.narrowCell,
                        d.percentTooWeak >= 25
                          ? styles.descriptorEmphasis
                          : null,
                      ]}
                    >
                      {Math.round(d.percentTooWeak)}%
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell width={90}>% Too Strong</TableCell>
                  {_.sortBy(sample.descriptors, (d) => d.name).map((d) => (
                    <TableCell
                      align="center"
                      key={d.id}
                      css={[styles.narrowCell,
                        d.percentTooStrong >= 25
                        ? styles.descriptorEmphasis
                        : null,]}
                    >
                      {Math.round(d.percentTooStrong)}%
                    </TableCell>
                  ))}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
