import React from "react";
import WordCloud, { WordCloudProps } from "../../components/WordCloud";
import { Card, CardContent, Typography } from "@material-ui/core";

export default function WordCloudCard(p: WordCloudProps) {
  return (
    <Card>
      <CardContent>
        <Typography>Word Cloud</Typography>
        <WordCloud {...p} size={[400,300]}/>
      </CardContent>
    </Card>
  );
}
