import { gql, useQuery } from "@apollo/client";

export const ObjectCountsQuery = gql`
query object_counts {
  project_aggregate {
    aggregate {
      count
    }
  }
  sample_aggregate {
    aggregate {
      count
    }
  }
  lexicon_aggregate {
    aggregate {
      count
    }
  }
  descriptor_aggregate {
    aggregate {
      count
    }
  }
  evaluation_aggregate {
    aggregate {
      count
    }
  }
  descriptor_rating_aggregate {
    aggregate {
      count
    }
  }
  app_user_aggregate {
    aggregate {
      count
    }
  }
}
`;

export interface ObjectCountsData {
  project_aggregate: {
    aggregate: {
      count: number;
    }
  };
  sample_aggregate: {
    aggregate: {
      count: number;
    }
  }
  lexicon_aggregate: {
    aggregate: {
      count: number;
    }
  }
  descriptor_aggregate: {
    aggregate: {
      count: number;
    }
  }
  evaluation_aggregate: {
    aggregate: {
      count: number;
    }
  }
  descriptor_rating_aggregate: {
    aggregate: {
      count: number;
    }
  }
  app_user_aggregate: {
    aggregate: {
      count: number;
    }
  }
}

export default function useObjectCounts() {
  return useQuery<ObjectCountsData>(ObjectCountsQuery, {pollInterval: 2000});
}