import { css } from "@emotion/react";
import { Box, Button, CardContent, Typography } from "@material-ui/core";
import React, { useRef, useState } from "react";
import Logo from "../components/Logo";
import SurveyCard from "../components/SurveyCard";
import { mapApollo } from "../util/ApolloLoadable";
import ApolloRouter from "../util/ApolloRouter";
import {
  RespondentSurveyDetailsData,
  useRespondentSurveyDetailsQuery,
} from "./queries/RespondentSurveyDetails";
import { useSubmitSurveyResponseMutation } from "./queries/SubmitSurveyResponse";
import SurveyForm, { Answers, SurveyFormDataProps } from "./SurveyForm";

const styles = {
  container: css`
    height: 100%;
    width: 100%;
    background-color: #ffeeff;
    overflow-y: scroll;
  `,
  contentArea: css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
  `,
};

export interface SurveyPageProps {
  surveyId: string;
}

export default function SurveyPage(p: SurveyPageProps) {
  const query = useRespondentSurveyDetailsQuery({
    variables: { survey_id: p.surveyId },
  });
  const [submitResponse] = useSubmitSurveyResponseMutation();
  const [submissionId, setSubmissionId] = useState<string | null>(null);
  const pageRef = useRef<HTMLDivElement>(null);

  const handleSubmit = (answers: Answers) => {
    if (query.data?.SUR_survey_by_pk?.questions == null) {
      alert("Error, no question data available");
      return;
    }
    const responses = query.data.SUR_survey_by_pk.questions.map((q) => ({
      question_id: q.id,
      value: answers[q.id],
    }));
    submitResponse({
      variables: { survey_id: p.surveyId, question_responses: responses },
    }).then((result) => {
      if (result.data?.insert_SUR_response_one?.id == null) {
        alert("Submission failed");
      } else {
        setSubmissionId(result.data?.insert_SUR_response_one.id);
      }
    });
  };

  const handleError = () => {
    pageRef.current?.scrollTo({top: 0, behavior: "smooth"});
  }

  const handleNewResponse = () => {
    window.location.reload(true);
  };

  return (
    <div css={styles.container} ref={pageRef}>
      <div css={styles.contentArea}>
        <Logo />
        {submissionId !== null ? (
          <SurveyCard>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
              >
                <Typography variant="h2">Thank you</Typography>
                <Typography variant="subtitle1">
                  Your answers have been recorded.
                </Typography>
                <Typography variant="caption">
                  Reference: {submissionId}
                </Typography>
                <Box paddingTop={3}>
                  <Button
                    onClick={handleNewResponse}
                    variant="contained"
                    color="primary"
                  >
                    Start a new response
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </SurveyCard>
        ) : (
          <ApolloRouter
            loadable={mapApollo(query, mapFormData)}
            ready={(data) => (
              <SurveyForm
                {...data}
                onSubmit={handleSubmit}
                onError={handleError}
              />
            )}
          />
        )}
      </div>
    </div>
  );
}

function mapFormData(data: RespondentSurveyDetailsData): SurveyFormDataProps {
  return {
    name: data.SUR_survey_by_pk.name,
    description: data.SUR_survey_by_pk.description,
    questions: data.SUR_survey_by_pk.questions.map((question, index) => ({
      id: question.id,
      index: index,
      type: question.type,
      name: question.name,
      description: question.description || "",
      required: question.required,
      min_value: question.min_value,
      max_value: question.max_value,
      answer: question.answer || "",
      parent: question.parent || "",
      logic_value: question.logic_value || "",
      validationMode: question.validation_mode,
      validationCount: question.validation_count,
      validationErrorMessage: question.validation_error_message,
      options: question.options.map((option) => ({
        id: option.id,
        text: option.text,
      })),
    })),
  };
}
