import React from "react";
import { css } from "@emotion/react";
import Tabs, { TabPage } from "./Tabs";
import EditProject from "./EditProject";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useMutation } from "@apollo/client";
import ReportTab, { ProjectReportData } from "./report/ReportTab";
import { mapApollo } from "../../../util/ApolloLoadable";
import { getSummaryTableData, ProjectSummaryTableData, UpdateProjectSharingData, updateProjectSharingMutation, UpdateProjectSharingVariables, useProjectSummaryTable as useProjectReportQuery } from "../../../components/ProjectSummaryTable/query";
import { DescriptorStats } from "./report/DescriptorTable";
import { SampleDatum } from "./report/SampleOverviewTable";

const styles = {
  container: css``,
};

interface SessionPageProps {
  sessionId: string;
}

export function SessionPage(p: SessionPageProps) {
  // Tab Handling
  const history = useHistory();
  const match = useRouteMatch<{
    sessionId: string;
    sampleId: string;
    tab: string;
  }>("/admin/sessions/:sessionId/:tab?");
  const handleTabChange = (newTab: string) => {
    history.replace(
      `/admin/sessions/${match?.params.sessionId}/${newTab}`
    );
  };
  const tableQuery = useProjectReportQuery({variables: {project_id: p.sessionId}});
  const [toggleSharing] = useMutation<UpdateProjectSharingData, UpdateProjectSharingVariables>(updateProjectSharingMutation);

  const handleTableShareChange = (newValue: boolean) => {
    toggleSharing({variables: {project_id: p.sessionId, share_with_evaluators: newValue}, refetchQueries: ["ProjectReport"]});
  }

  function getTabDisplay() {
    switch (match?.params.tab) {
      case "report":
        return (
          <ReportTab data={mapApollo(tableQuery, mapReportTabData)} onTableShareChange={handleTableShareChange}/>
        );
      case "overview":
      default:
        return <EditProject id={p.sessionId}/>;
    }
  }

  return (
    <div css={styles.container}>
      <Tabs
        value={(match?.params.tab || "overview") as TabPage}
        onChange={(e, value) => handleTabChange(value)}
      />
      {getTabDisplay()}
    </div>
  );
}

function mapReportTabData(data: ProjectSummaryTableData): ProjectReportData | null {
  if (!data.project_by_pk) {
    return null;
  }
  return {
    name: data.project_by_pk.name,
    shareTable: data.project_by_pk.share_with_evaluators,
    tableData: getSummaryTableData(data),
    descriptorStats: getDescriptorStats(data),
    sampleOverviewData: getSampleOverviewData(data),
    evaluatorCount: data.evaluation_aggregate.aggregate.count
  }
}

function getDescriptorStats(data: ProjectSummaryTableData): DescriptorStats[] {
  return data.project_by_pk!.samples.map(sample => ({
    id: sample.id,
    name: sample.name,
    descriptors: sample.evaluation_descriptor_summary.map(summary => ({
      id: summary.descriptor.id,
      name: summary.descriptor.name,
      tasters: summary.tasters_count,
      percentTooWeak: summary.score_1_percent_of_tasters,
      percentTooStrong: summary.score_3_percent_of_tasters
    }))
  }))
}

function getSampleOverviewData(data: ProjectSummaryTableData): SampleDatum[] {
  return data.project_by_pk!.samples.map(sample => ({
    id: sample.id,
    name: sample.name,
    tasters: sample.evaluation_summary?.count,
    likingMean: sample.evaluation_summary?.liking_mean,
    likingMedian: sample.evaluation_summary?.liking_median,
    likingEntries: sample.evaluation_summary?.liking_entries,
    conceptFitMean: sample.evaluation_summary?.concept_fit_mean,
    conceptFitMedian: sample.evaluation_summary?.concept_fit_median,
    conceptFitEntries: sample.evaluation_summary?.concept_fit_entries,
    justAboutRightPercent: sample.evaluation_summary?.just_about_right_percent,
    justAboutRightCount: sample.evaluation_summary?.score_2_sum,
    justAboutRightPossibleCount: sample.evaluation_summary?.tasters_sum
  }))
}